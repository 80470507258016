import React from "react";
import { Link } from "react-router-dom";

import { makeStyles, withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import logo from "../../../assets/images/logo.png";
import { useStyles } from "./styles";
const styles = makeStyles(useStyles);
const RegistrationHeader = React.memo(({children,headerClass='',footerClass=''}) => {
  const classes = styles();
  return (<>
    <Grid container className={`${classes.registrationHeaderWrapper} ${headerClass}`}>
      <Grid xs={12} sm={12} md={12} lg={12} className={classes.logoContainer}>
        <h1>
          <Link to="/">
            <img src={logo} alt="Niram Healthcare" />
          </Link>
        </h1>
      </Grid>
    </Grid>
    <div className={classes.contentContainer}>
      {children}
    </div>
    <Typography className={`${classes.footerText} ${footerClass}`} variant="body1">Copyright {new Date().getFullYear()} iNaira Healthcare. All Rights Reserved.</Typography>
    </>
  );
});

export default RegistrationHeader;
