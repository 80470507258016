import {platformCoreActions} from '../actionCreators'

const InitialServiceSubscription={
    serviceSubscriptions:[],
    services:[],
    announcements: {
        displayStatus: true,
        messages: [],
    },
}

const platformCoreReducer = (state = InitialServiceSubscription, action) => {

    switch (action.type) {
        case platformCoreActions.UPDATE_SERVICES: {
            return { ...state, services: action.payload };
        }
        case platformCoreActions.UPDATE_SERVICE_SUBSCRIPTIONS: {
            return { ...state, serviceSubscriptions: action.payload };
        }
        /*case platformCoreActions.UPDATE_SUBSCRIBED_SERVICE_ID: {
            return { ...state, subscribedServiceId: action.payload};
        }*/
        case platformCoreActions.ADD_SERVICE_SUBSCRIBED: {
            return {...state, serviceSubscriptions: [...state.serviceSubscriptions, action.payload]};
        }
        case platformCoreActions.UPDATE_ANNOUNCEMENTS: {
            return {...state, announcements: {...state.announcements, messages: action.payload}};
        }
        case platformCoreActions.UPDATE_ANNOUNCEMENTS_VISIBILITY: {
            return {...state, announcements: {...state.announcements, displayStatus: action.payload }};
        }
        default: {
            return state;
        }

    }
}

export default platformCoreReducer;
