const CUSTOM_LOADER = {
  RECOVIA:'/static/images/custom-loader-recovia.gif'
}
export const LoaderContent = {
  APP_INIT: {
    title: "Initializing application",
    content: "Initializing the application",
  },
  SIGNUP: {
    title: "Signing Up",
    content: "Please wait. we are creating an account for you",
  },
  LOGIN: {
    title: "Logging In",
    content: "Please wait while we log you in to the portal",
  },
  DELETE_USER: {
    title: "Deleting your Account",
    content: "Your user profile will be deleted",
  },
  FORGOT_PASSWORD: {
    title: "Forgot Password",
    content: "Sending you an email with the verification code",
  },
  FORGOT_PASSWORD_SUBMIT: {
    title: "Forgot Password Submit",
    content: "Resetting your password",
  },
  CHANGE_PASSWORD: {
    title: "Change Password",
    content: "Updating your password",
  },
  SIGN_OUT: {
    title: "Signing Out",
    content: "You will be signed out of the portal",
  },
  UPDATE_PROFILE: { 
    title: "Updating", 
    content: "Filtering the services" 
  },
  PLATFORM_CORE_SERVICES: {
    title: "Fetching Platform Core Services",
    content: "Shortly we are displaying Platform Core Services"
  },
  ADD_NEW_PLATFORM_CORE_SERVICE: {
    title: "Adding new Platform Core Service",
    content: "Please Wait. We are adding new service"
  },
  UPDATE_PLATFORM_CORE_SERVICE: {
    title: "Updating Platform Core Service",
    content: "Please Wait. We are updating existing service details"
  },
  LEAD_GENERATION: {
    title: "Saving lead generation", 
    content: "Shortly we are landing to dashboard",
  },
  UPDATE_PLATFORM_CORE_SUBSCRIBED_SERVICES:{
    title: "Updating Subscribed Services",
    content: "We are updating the new subscriptions you selected",
  },
  PAYER_MEASURE_DETAILS:{
    title: "Loading Payer and Measure Details",
    content: "Please wait, shortly we are displaying active payers and measures details",
  },
  MEASURE_DETAILS:{
    title: "Fetching measure details",
    content: "We are fetching the measure details"
  },
  UPDATE_PAYERS_MEASURES_DETAILS:{
    title: "Updating measure details",
    content: "We are saving the changed measure details..."
  },
  CONTACT_US: {
    title: "Saving Contact Us", 
    content: "Please wait while we save contact us details",
  },
  ADT_SERVICES: {
    title:  "Fetching patient details",
    content: "Shortly we are displaying details"
  },
  ADT_PATIENT_FOLLOWUPS: {
    title:  "Fetching patient follow ups",
    content: "Shortly we are displaying details"
  },
  ADT_FILES_PROCESSED: {
    title:  "Fetching processed files",
    content: "Shortly we are displaying details"
  },
  ADT_FILE_UPLOAD: {
    title:  "Uploading file",
    content: "File upload is in progress"
  },
  ADT_PATIENT_FOLLOWUP_ADD: {
    title:  "Adding patient follow up",
    content: "Please wait while we add the follow up"
  },
  DASHBOARD: {
    title: "Fetching Dashboard",
    content: "Please wait while we fetch the dashboard",
  },
  DASHBOARD_CONFIGURE: {
    title: "Loading Dashboard",
    content: "Please wait while we load the dashboard",
  },
  SUPPORT_REQUEST_DETAILS: {
    title: "Loading...",
    content: "Please wait while we load the support requests"
  },
  SUBMIT_REQUEST: {
    title: "Submitting Request",
    content: "Please wait submitting new request",
  },
  CLINIC_LOCATION_FETCH: {
    title: "Loading Clinic Locations",
    content: "Please wait while we load the clinic locations",
  },
  CLINIC_LOCATION_UPDATE: {
    title: "Saving Clinic Location",
    content: "Please wait while we save the clinic location details",
  },
  CLINIC_REPORT_DATA_IMPORT: {
    title: "Importing Clinic Report Data",
    content: "Please wait while we upload the clinic report data",
  },
  CLINIC_DATA_IMPORT_HISTORY: {
    title: "Fetching Data Import Details",
    content: "Please wait while we load the data import details...",
  },
  GET_PATIENT_DETAILS: {
    title: "Fetching Patient Details",
    content: "Please wait while we load the patient details..."
  },
  SAVE_VIEW: {
    title: "Saving Patient Records",
    content: "Please wait, Filter details are saving"
  },
  DELETE_VIEW: {
    title: "Deleting View",
    content: "Please wait, the selected view will be deleted immediately"
  },
  EXPORT_VIEW: {
    title: "Downloading Patient Records",
    content: "Please wait, downloading the patient details"
  },
  USER_FETCH: {
    title: "Loading Users",
    content: "Please wait while we load the users",
  },
  USER_UPDATE: {
    title: "Updating User",
    content: "Please wait while we update the user",
  },
  USER_INVITATION_FETCH: {
    title: "Loading User Invitations",
    content: "Please wait while we load the user invitations",
  },
  USER_INVITATION_UPDATE: {
    title: "Saving Invitation",
    content: "Please wait while we save the user invitation details",
  },
  LOGO_UPDATE: {
    title: "Updating logo",
    content: "Please wait while we update the uploaded logo",
  },
  CREATE_ROLE: {
    title: "Creating Role",
    content: "Please wait while we create a role",
  },
  UPDATE_ROLE: {
    title: "Updating Role",
    content: "Please wait while we update a role",
  },
  DELETE_ROLE: {
    title: "Deleting Role",
    content: "Please wait while we delete a role",
  },
  NLP_SEARCH: {
    title: "Searching",
    content: "Please wait, loading your search result"
  },
  GET_PATIENT_TRIAL_DETAILS: {
    title: "Fetching Patient Trial Details",
    content: "Please wait while we load the patient trial details..."
  },
  GET_SMART_SCHEDULER_DETAILS: {
    title: "Fetching Smart Scheduler Details",
    content: "Please wait while we load the smart scheduler details...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_SMART_SCHEDULER_SETTINGS: {
    title: "Fetching Smart Scheduler Setting Details",
    content: "Please wait while we load the smart scheduler setting details...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_SMART_SCHEDULER_PATIENT_PREF: {
    title: "Fetching Smart Scheduler Patient Preferences",
    content: "Please wait while we load the smart scheduler patient preferences...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_PATIENT_APPOINTMENTS: {
    title: "Fetching Patient Appointments",
    content: "Please wait while we load the Patient Appointments...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_OPT_PATIENT_APPOINTMENTS: {
    title: "Fetching Patient Appointments For Optimization",
    content: "Please wait while we load the Patient Appointments...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  OPT_PATIENT_APPOINTMENTS: {
    title: "Optimizing Patient Appointments",
    content: "Please wait while we are optimizing the patient appointments...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  UPDATING_PATIENT_APPOINTMENT_STATUS: {
    title: "Updating appointment status",
    content: "Please wait while we are updating the patient appointment status...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  UPDATING_SMART_SCHEDULER_SETTINGS: {
    title: "Updating smart scheduler settings",
    content: "Please wait while we are updating the smart scheduler settings...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_PATIENT_COMPLIANCE_METRICS: {
    title: "Fetching Patient Compliance metrics",
    content: "Please wait while we load the Patient Compliance metrics...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_PATIENT_AUTH_INFO: {
    title: "Fetching Patient Authorization Info",
    content: "Please wait while we load the Patient Authorization info...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_PATIENT_APPOINTMENT_NOTES: {
    title: "Fetching Patient Appointment notes",
    content: "Please wait while we load the Patient Appointment note...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_PATIENT_PROVIDERS: {
    title: "Fetching Provider list",
    content: "Please wait while we load the Provider list...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  UPDATE_APPOINTMENT_DETAILS: {
    title: "Updating Patient Appointment details",
    content: "Please wait while we are updating the Patient Appointment details...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  UPDATE_PATIENT_PREF: {
    title: "Updating Patient Preferences",
    content: "Please wait while we are updating the Patient Preferences...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  REVERT_PATIENT_MOVE: {
    title: "Reverting patient move",
    content: "Please wait while we are reverting the patient move...",
    loader:CUSTOM_LOADER.RECOVIA
  },
  GET_FILTER_VALUES: {
    title: "Fetching Filter Details",
    content: "Please wait while we load the patient trial details..."
  }
};
