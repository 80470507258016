import React, { Suspense, useEffect } from "react";
import { BrowserRouter as ReactRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import loadable from '@loadable/component';
import { GlobalRoutes,AuthRequiredPaths } from "./Routes";
import {postLoginPath } from "./constants";
import { loginActions } from "@redux/actionCreators/UserActions";
import PrivateRoute,{ChildRoute} from "@components/PrivateRoute";
import PageNotFound from "@components/common/PageNotFound";

import store from "@redux/Store";
const setupRouteAuthentications = () => {
  const state = store.getState();
  const { isLoggedIn } = state.userReducer;
  
  const authRoutes = Object.keys(GlobalRoutes).reduce((ar,rk)=>{
    const r = GlobalRoutes[rk];
    ar[rk] = {...GlobalRoutes[rk],isLoggedIn,isAllowed: (!r.authRequired || isLoggedIn),redirectTo: r.authRequired?GlobalRoutes.LOGIN.path:postLoginPath()};
    return ar;
  },{});
  //console.log('authRoutes',authRoutes);
  return {
    ...GlobalRoutes,
    ...authRoutes
  };
};
const asyncCompnent =(componentPath,loadableComp)=> {
  const lc = loadableComp || (() => import(componentPath));
  return loadable(lc, {
  fallback: <LinearProgress />,
});
}
class GlobalRouter extends React.Component {
  constructor(props) {
    super(props);
    this.onRouteChange = this.onRouteChange.bind(this);
  }
  componentDidMount() {
    this.props.initApp();
    this.path = location.path;
  }
  onRouteChange(path,isLoggedIn){
    path!=this.path && this.props.onRouteChange(path,this.path,isLoggedIn);
    this.path = path;
  }
  render() {
    if (!this.props.isInitialized) {
      return <LinearProgress />;
    }
    const {DEFAULT:defaultPageRoute,...routes} = setupRouteAuthentications();
    const getComponent = (routeInfo)=>(routeInfo.componentPath||routeInfo.loadablComp)?asyncCompnent(routeInfo.componentPath,routeInfo.loadablComp):routeInfo.component
    const commomProps = {onRouteChange:this.onRouteChange}
    return (
      <ReactRouter>
        <Suspense fallback={<LinearProgress />}>
          <Routes>
            {[defaultPageRoute,...Object.values(routes)].map((route, index) => {
              const {DEFAULT:defaultRoute,...nextedRoutes} = route.nextedRoutes || {};
              let Comp;
              let chilrdRoutes = nextedRoutes? Object.values(nextedRoutes).map(nroute=>{
                return <Route key={route.path + index+ nroute.path} path={nroute.path} element={<ChildRoute  childComponent {...commomProps} isLoggedIn={route.isLoggedIn} path={nroute.fullPath} component={getComponent(nroute)}/>} />}
              ):[];
              defaultRoute && route.nextedRoutes && (chilrdRoutes = [<Route index key={route.path + index+ defaultRoute.path} element={<ChildRoute  {...commomProps} isLoggedIn={route.isLoggedIn} childComponent path={defaultRoute.fullPath} component={getComponent(defaultRoute)}/>} />,...chilrdRoutes,<Route key={route.path + index+ defaultRoute.path+'default'} path="*" element={<ChildRoute  {...commomProps} childComponent isLoggedIn={route.isLoggedIn} component={getComponent(defaultRoute)}/>} />])
              //chilrdRoutes.length && console.log('chilrdRoutes',chilrdRoutes);
              return <Route
                  key={route.path + index}
                  path={route.path}
                  element={
                    <PrivateRoute
                      {...commomProps} 
                      hasChildRoutes={!!chilrdRoutes.length}
                      authRequired={!!route.authRequired}
                      path={route.path}
                      component={getComponent(route)}
                      isLoggedIn={route.isLoggedIn}
                      redirectTo={route.redirectTo}
                      layout={route.layout}
                      layoutOpt={route.layoutOpt}
                    />
                  }
                >
                  {chilrdRoutes}
                </Route>

            })}
            <Route path="*" element={<PageNotFound/>} />
          </Routes>
        </Suspense>
      </ReactRouter>
    );
  }
}

const mapStateToProps = state => ({
  isInitialized: state.userReducer.isInitialized,
  isLoggedIn: state.userReducer.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  initApp: () =>{
    loginActions.IHP_CHANNEL.onmessage = ({event,uid,data})=>{
      const {appContext,CHANNEL_EVENTS,signout,refreshSession,expirySession,updateUserSelectedClinicId} = loginActions;
      console.log('event',event,uid,appContext.uid);
      switch(event){
        case CHANNEL_EVENTS.SIGN_IN: !appContext.user && window.location.reload();break;
        case CHANNEL_EVENTS.SIGN_OUT: appContext.user && dispatch(signout);break;
        case CHANNEL_EVENTS.CLINIC_SWITCH: appContext.user && (location.pathname = GlobalRoutes.LANDING.path) ;break;
        case CHANNEL_EVENTS.SESSION_REFRESHED:appContext.user && dispatch(refreshSession(data.lat));break;
        case CHANNEL_EVENTS.SESSION_EXPIRED:appContext.user && dispatch(expirySession);break;
      }
    }

    dispatch(loginActions.initializeApplication);
  },
  onRouteChange:(path,oldPath,isLoggedIn)=>{
    console.log('new path',path,oldPath,isLoggedIn);
    if(AuthRequiredPaths.includes(path)){
      localStorage.setItem(_app.u.luid+"lpath",location.pathname);
      //dispatch(errorActions.resetCustomAuthenticationErrorCode());
    } 
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRouter);
