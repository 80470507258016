import React, { useEffect, useState} from "react";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {SHOW_GLOBAL_TOAST,HIDE_GLOBAL_TOAST} from "@utils/Events/constants";

const ToastMessage = ({global=false,show=true,autoHideDuration=10000,message,type="error"})=>{
    const closeToast = ()=>setState((pv)=>({...pv,show:false}));
    const dfValue = {message,autoHideDuration,type};
    const [state,setState] = useState({show:!!show,...dfValue});
    const showToast = (props={})=>{
        setState((pv)=>({...pv,show:true,message:(props.message || message),type:(props.type || type),autoHideDuration:(props.autoHideDuration || autoHideDuration)}))
    }
    useEffect(()=>{
        !global && setState((pv)=>({...pv,show:!!show}));
    },[show])
    useEffect(()=>{
        const subKey = global?_app.events.subscribe({[SHOW_GLOBAL_TOAST]:showToast,[HIDE_GLOBAL_TOAST]:closeToast}):null;
        return()=>{
            subKey && _app.events.unsubscribe(subKey);
        }
    },[])
    return(<Snackbar 
            anchorOrigin={{ vertical:'top', horizontal:'center' }} 
            open={!!state.message && state.show} 
            autoHideDuration={state.autoHideDuration} 
            onClose={closeToast}>
                <MuiAlert 
                    elevation={10} 
                    variant="filled" 
                    onClose={closeToast} 
                    severity={state.type || "error"}>
                    {state.message}
                </MuiAlert>
            </Snackbar>)
}

export default ToastMessage;