import React from "react";
import { Link } from "react-router-dom";
import InairaHealthLogo from "@assets/images/logo.png";
import { makeStyles } from "@mui/styles";
import { height } from "@mui/system";

const useStyles = makeStyles(MUITheme => ({
  logoContainer:{
    height:70,
    '&.mini':{
      height:40
    },
    '& img':{
      maxHeight:'100%'
    },
    '&.mb-16':{
      marginBottom:16,
    },
    '&.mb-50':{
      marginBottom:50,
    }
      
  }
}));
const Logo = ({className=''}) => {
  const classes = useStyles();
  return <div className={`logoCtr ${classes.logoContainer} ${className}`}><Link to={"/"}><img src={InairaHealthLogo} alt="iNaira Healthcare" /></Link></div>;
};

export default Logo;
