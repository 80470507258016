import AwsAmplifyApi from "../../utility/AwsAmplifyApi";
import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";

import { errorActions, loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_BASIC_PATIENT_DETAILS_LIST = "UPDATE_BASIC_PATIENT_DETAILS_LIST";
export const UPDATE_OTHER_DETAILS = "UPDATE_OTHER_DETAILS";
export const UPDATE_METRICS_VALUE = "UPDATE_METRICS_VALUE";
export const UPDTAE_BASIC_INFO = "UPDTAE_BASIC_INFO";
export const UPDATE_ONE_PATIENT_DETAILS = "UPDATE_ONE_PATIENT_DETAILS";
export const UPDATE_PAGINATOR = "UPDATE_PAGINATOR";
export const UPDATE_FILTER_COLUMNS = "UPDATE_FILTER_COLUMNS";
export const UPDATE_FILTER_OBJECT = "UPDATE_FILTER_OBJECT";
export const UPDATE_VIEW_LIST = "UPDATE_VIEW_LIST";
export const UPDATE_FILE_CONTENT = "UPDATE_FILE_CONTENT";
export const UPDATE_PATIENT_DETAILS = "UPDATE_PATIENT_DETAILS";
export const UPDATE_CUURENT_COUNT = "UPDATE_CURRENT_COUNT";

const default_metrics = {
    'high_risk_patients': 0,
    'medium_risk_patients': 0,
    'low_risk_patients': 0,
    'total_patients': 0
}

//var queryStringParameters={};
const setPatientData = (details) => ({
    type: UPDATE_PATIENT_DETAILS,
    payload: details,
})

const setBasicDetails = (basicDetails) => dispatch => {
    dispatch({
        type: UPDATE_BASIC_PATIENT_DETAILS_LIST,
        payload: basicDetails
    })
};

// const setOtherDetails = (otherDetails) => ({
//     type: UPDATE_OTHER_DETAILS,
//     payload: otherDetails
// })

const setTotalCount = (value) => dispatch => {
    var metrics = default_metrics;
    if (value !== undefined && value !== "" && value !== null) {
        metrics = value;
    }
    dispatch({
        type: UPDATE_METRICS_VALUE,
        payload: metrics
    })
}

const addBasicDetails = (details) => ({
    type: UPDTAE_BASIC_INFO,
    payload: details
})

const addPatientDetails = (patientDetails) => ({
    type: UPDATE_ONE_PATIENT_DETAILS,
    payload: patientDetails
})

export const updatePaginator = (paginator) => ({
    type: UPDATE_PAGINATOR,
    payload: paginator
})

export const updateFilterValues = (filterValues) => ({
    type: UPDATE_FILTER_COLUMNS,
    payload: filterValues
})

export const updateCurrentPAtientCount = (count) => ({
    type: UPDATE_CUURENT_COUNT,
    payload: count
})

export const updateWithEmptyObject = () => dispatch => {
    const singlePatientDetails = {
        'patient_account_number': "",
        'race': undefined,
        'ethnicity': undefined,
        'home_phone': undefined,
        'work_phone': undefined,
        'email_id': undefined,
        'address': undefined,
        'no_show_prediction': null,
        'claims_rejected': undefined,
        'gaps_pending': [],
        'gaps_closed': [],
        'chronic_data': [],
        'last_5_visits': []
    };
    dispatch({
        type: UPDATE_ONE_PATIENT_DETAILS,
        payload: singlePatientDetails
    })
}

export const setFilterObject = (filter) => ({
    type: UPDATE_FILTER_OBJECT,
    payload: filter
})

export const setViewList = (viewList) => ({
    type: UPDATE_VIEW_LIST,
    payload: viewList
})

export const setFileContent = (file) => ({
    type: UPDATE_FILE_CONTENT,
    payload: file,
})

const updateErrorCode = err => dispatch => {
    dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

//************ Set querystringParameter */

const setQueryStringParam = (offset, records_per_page, filter, search_key) => {
    const queryStringParameters = {
        offset,
        records_per_page,
        "gender": filter.gender,
        "min_no_show_prob": filter.min_no_show_prob,
        "max_no_show_prob": filter.max_no_show_prob,
        "min_chronic_count": filter.min_chronic_count,
        "max_chronic_count": filter.max_chronic_count,
        search_key,
        "sort_key": filter.sort_key,
        "is_descending_order": filter.is_descending_order,
        "risk_filters": filter.risk_filters,
        "age_groups": filter.age_groups,
        "pcps": filter.pcps,
        "insurances": filter.insurances,
        "locations": filter.locations,
        "active_status": filter.active_status,
        "deceased_status": filter.deceased_status,
    }
    return queryStringParameters;
}

const fetchMetricsApi = (clinicId, active_status, deceased_status) => async dispatch => {
    const queryString = {
        active_status,
        deceased_status
    }
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.METRICS;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, queryString,clinicId}));
    return data;
}


//************************* Get basic details of patient */

const fetchBasicPatientDetailsApi = (clinicId, queryString) => async dispatch => {
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.BASIC_PATIENT_DETAILS;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId, queryString}));
    return data;
}

//*************************** Get patient History such lastOfficeVisit, PCP etc. */

const fetchPatientHistoryDetails = (clinicId, queryString) => async dispatch => {
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PATIENT_HISTORY_DETAILS;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId, queryString}));
    return data;
}

//**************************** Get Patient treatment details */
const fetchPatientTreatmentDetails = (clinicId, queryString) => async dispatch => {

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PATIENT_TREATMENT_DETAILS;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId, queryString}));
    return data;
}


//**************************** Get Filter values */
const fetchFilterDetails = (clinicId) => async dispatch => {

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PATIENT_FILTER_VALUES;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
    return data;
}

export const fetchBasicPatientDetails = (selectedClinicId, filter, search_key, offset, records_per_page) => async dispatch => {
    try {
        dispatch(setBasicDetails([]));
        dispatch(updateErrorCode(undefined));
        if (selectedClinicId !== undefined && selectedClinicId !== null && selectedClinicId !== "") {
            const queryStringParameters = setQueryStringParam(offset, records_per_page, filter, search_key);

            dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_DETAILS));
            dispatch(updateWithEmptyObject());
            const response = await dispatch(fetchBasicPatientDetailsApi(selectedClinicId, queryStringParameters));
            dispatch(addBasicDetails(response.data.content));
            dispatch(updateCurrentPAtientCount(response.data.count));
            dispatch(loaderActions.stopAppLoader());
            await Promise.all([
                dispatch(fetchMetricsApi(selectedClinicId, filter.active_status, filter.deceased_status)).then(response => {
                    dispatch(setTotalCount(response.data));
                }),
                // dispatch(fetchBasicPatientDetailsApi(selectedClinicId, queryStringParameters)).then(response => {
                //     dispatch(addBasicDetails(response.data.content));
                //     dispatch(updateCurrentPAtientCount(response.data.count));
                // }),
                dispatch(fetchFilterDetails(selectedClinicId)).then(response => {
                    dispatch(updateFilterValues(response.data));
                }),
                dispatch(fetchPatientHistoryDetails(selectedClinicId, queryStringParameters)).then(response => {
                    dispatch(setPatientData(response.data.content));
                    // dispatch(addBasicDetails(response.data.content));
                }),
                dispatch(fetchPatientTreatmentDetails(selectedClinicId, queryStringParameters)).then(response => {
                    dispatch(setPatientData(response.data.content));
                    // dispatch(addBasicDetails(response.data.content));
                })
            ]);

            // //********* /patient/metrics api */

            // dispatch(fetchMetricsApi(selectedClinicId, filter.active_status)).then(response => {
            //     dispatch(setTotalCount(response.data));
            // })

            // //******* /patient api (add patiient info and sort the array based on the response from /patient api)*/

            // const response = await dispatch(fetchBasicPatientDetailsApi(selectedClinicId, queryStringParameters));
            // dispatch(addBasicDetails(response.data.content));
            // dispatch(updateCurrentPAtientCount(response.data.count));
            // dispatch(loaderActions.stopAppLoader());

            // //********* /patient/filter api */

            // dispatch(fetchFilterDetails(selectedClinicId)).then(response => {
            //     dispatch(updateFilterValues(response.data));
            // })

            // //********* /patient/history api */

            // dispatch(fetchPatientHistoryDetails(selectedClinicId, queryStringParameters)).then(response => {
            //     dispatch(setPatientData(response.data.content));
            // });

            // //********* /patient/treatment api */

            // dispatch(fetchPatientTreatmentDetails(selectedClinicId, queryStringParameters)).then(response => {
            //     dispatch(setPatientData(response.data.content));
            // })
        }
    }
    catch (error) {
        dispatch(setTotalCount(default_metrics));
        dispatch(loaderActions.stopAppLoader());
        const data = [];
        dispatch(setBasicDetails(data));
        if (error.response.status === 403) {
            dispatch(updateErrorCode(403));
        }
    }
}



//**************************** Get the details of patient based on the patient_number */

const fetchSinglePatientDetailsApi = (clinicId, patient_account_number) => async dispatch => {
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PATIENT_OTHER_DETAILS(patient_account_number);

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
}

export const fetchSinglePatientDetails = (selectedClinicId, patientAccountNumber) => async dispatch => {
    try {
        dispatch(updateWithEmptyObject());
        const response = await dispatch(fetchSinglePatientDetailsApi(selectedClinicId, patientAccountNumber));
        dispatch(addPatientDetails(response.data));
    } catch (error) {
        const data = {};
        dispatch(addPatientDetails(data));
        return
        // throw error;
    }
}

//**************** Fetch saved views */

const fetchViewDetailsApi = (clinicId) => async dispatch => {

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.VIEW_DETAILS;

    const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
    return data;
}

export const viewDetilsApi = (selectedClinicId) => async dispatch => {
    try {
        if (selectedClinicId !== undefined && selectedClinicId !== null && selectedClinicId !== "") {
            const response = await dispatch(fetchViewDetailsApi(selectedClinicId));
            dispatch(setViewList(response.data));
        }
    } catch (error) {
        dispatch(setViewList([]));
        // throw error;
        return
    }
}
//*********************** Save view */

const fetchSaveViewApi = (clinicId, search_key, filters, viewName) => async dispatch => {
    const body = {
        "view_name": viewName,
        filters: {
            "gender": filters.gender,
            "min_no_show_prob": filters.min_no_show_prob,
            "max_no_show_prob": filters.max_no_show_prob,
            "min_chronic_count": filters.min_chronic_count,
            "max_chronic_count": filters.max_chronic_count,
            search_key,
            "sort_key": filters.sort_key,
            "is_descending_order": filters.is_descending_order,
            "risk_filters": filters.risk_filters,
            "age_groups": filters.age_groups,
            "pcps": filters.pcps,
            "insurances": filters.insurances,
            "locations": filters.locations,
            'active_status': filters.active_status,
            'deceased_status': filters.deceased_status,
        }
    }
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.VIEW_DETAILS;

    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body,clinicId}));
}

export const saveViewApi = (selectedClinicId, search_key, filters, viewName) => async dispatch => {
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.SAVE_VIEW));
        await dispatch(fetchSaveViewApi(selectedClinicId, search_key, filters, viewName));
        dispatch(loaderActions.stopAppLoader());
    } catch (error) {
        dispatch(loaderActions.stopAppLoader());
        // throw error;
        return
    }
}


//********************* Delete a selected view */

const fetchDeleteViewApi = (clinicId, id) => async dispatch => {
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.DELETE_VIEW(id);

    return await dispatch(AwsAmplifyApi.put({apiName, apiPath, clinicId}));
}

export const deleteViewApi = (selectedClinicId, id) => async dispatch => {
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.DELETE_VIEW));
        await dispatch(fetchDeleteViewApi(id, selectedClinicId));
        // const data = [];
        // dispatch(setViewList(data));
        dispatch(loaderActions.stopAppLoader());
    } catch (error) {
        dispatch(loaderActions.stopAppLoader());
        // throw error;
        return
    }
}


//********************** Export View */

const fetchExportViewApi = (clinicId, filters, search_key) => async dispatch => {
    const queryString = {
        "gender": filters.gender,
        "min_no_show_prob": filters.min_no_show_prob,
        "max_no_show_prob": filters.max_no_show_prob,
        "min_chronic_count": filters.min_chronic_count,
        "max_chronic_count": filters.max_chronic_count,
        "last_office_visit": "",
        search_key,
        "sort_key": filters.sort_key,
        "is_descending_order": filters.is_descending_order,
        "locations": filters.locations,
        "age_groups": filters.age_groups,
        "risk_filters": filters.risk_filters,
        "pcps": filters.pcps,
        "insurances": filters.insurances,
        'active_status': filters.active_status,
        'deceased_status': filters.deceased_status
    }

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.EXPORT_VIEW;

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
}

export const exportViewApi = (selectedClinicId, filters, search_key) => async dispatch => {
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.EXPORT_VIEW));
        dispatch(setFileContent(""));
        const response = await dispatch(fetchExportViewApi(selectedClinicId, filters, search_key));
        dispatch(setFileContent(response.data));
        dispatch(loaderActions.stopAppLoader());
    } catch (error) {
        dispatch(loaderActions.stopAppLoader());
    }
}
