import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import {thunk} from "redux-thunk";
//import { configureStore } from '@reduxjs/toolkit'
import rootReducer,{REDUCERS} from "./reducers";

let composeEnhancers = compose;
const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
}; 

const store = configureStore(); 

//const store = configureStore({reducer:REDUCERS});
export default store;
