import AwsAmplifyApi from "../../utility/AwsAmplifyApi";
import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";

import { loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_CHART_DATA = "UPDATE_CHART_DATA";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const UPDATE_CHART_HEADING = "UPDATE_CHART_HEADING"

export const setData = data => ({
    type: UPDATE_CHART_DATA,
    payload: data,
})

export const setSearchText = text =>({
    type: UPDATE_SEARCH_TEXT,
    payload: text
})

export const setChartHeading = text =>({
    type: UPDATE_CHART_HEADING,
    payload: text
})

const audioToTextApi = (base64) => async _dispatch =>{
    const apiName = APIEndpoints.NHCNLPAPI.name
    const apiPath = APIPaths.AUDIO_TO_TEXT

    const body = {
        'audio': base64
    }
    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body}));
}

const audioToDataApi = (base64) => async _dispatch=>{
    const apiName = APIEndpoints.NHCNLPAPI.name
    const apiPath = APIPaths.AUDIO_TO_DATA

    const body = {
        'audio': base64,
        'is_graph_format_required':true
    }
    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body}));
}

export const audioToText = (base64) => async dispatch=>{
    dispatch(setSearchText(""));
    dispatch(setData({}));
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.NLP_SEARCH));
        // dispatch(audioToTextApi(base64)).then(response =>{
        //     dispatch(setSearchText(response.data));
        // })

        // dispatch(audioToDataApi(base64)).then(response =>{
        //     dispatch(setData(response.data));
        //     dispatch(loaderActions.stopAppLoader());
        // })
        const response = await dispatch(audioToTextApi(base64));
        dispatch(setSearchText(response.data));
        dispatch(setChartHeading(response.data))
        const res = await dispatch(audioToDataApi(base64));
        dispatch(setData(res.data));
        dispatch(loaderActions.stopAppLoader());
    }catch(e){
        dispatch(loaderActions.stopAppLoader())
        const data = {'table_data': {'row_values': [{}], 'column_names': []}, 'graph_type': 'table'}
        dispatch(setData(data))
        throw e;
    }
}

const textToDataApi = (text) => async _dispatch => {
    const apiName = APIEndpoints.NHCNLPAPI.name
    const apiPath = APIPaths.TEXT_TO_DATA

    const body = {
        text,
        'is_graph_format_required':true
    }
    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body}));
}

export const textToData = (text) => async dispatch =>{
    dispatch(setSearchText(""));
    dispatch(setChartHeading(""));
    try{
            dispatch(loaderActions.startAppLoader(LoaderContent.NLP_SEARCH));
            const response = await dispatch(textToDataApi(text))
            dispatch(setData(response.data))
            dispatch(loaderActions.stopAppLoader())
    }catch(error){
        const data = {'table_data': {'row_values': [{}], 'column_names': []}, 'graph_type': 'table'}
        dispatch(setData(data))
        dispatch(loaderActions.stopAppLoader())
        throw error
    }
}


//########################### Feedback api ###################

const fetchFeedbackApi = (semanticsId, isCorrect) => async _dispatch=>{
    const apiName = APIEndpoints.NHCNLPAPI.name
    const apiPath = APIPaths.FEEDBACK

    const body = {
        "semantics_id": semanticsId,
        "is_correct": isCorrect
    }
    return await dispatch(AwsAmplifyApi.put({apiName, apiPath, body}));
}

export const feedbackData = (semanticsId, isCorrect) => async dispatch =>{
    try{
        await dispatch(fetchFeedbackApi(semanticsId, isCorrect));
    }catch(error){
        throw error;
    }
}
