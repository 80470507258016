import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";


import {GlobalRoutes} from "../../../GlobalRouter/Routes";
import logo from "../../../assets/images/logo.png";
import NHCButton from "../../common/Button";
import { useStyles } from "./styles";

const RegistrationHeader =  ({classes})  => {

  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate(GlobalRoutes.SIGNUP.path);
  }

  const handleLoginClick = () => {
    navigate(GlobalRoutes.LOGIN.path);
  }

  return (
    <Grid container className={classes.plainHeaderContainer}>
      <Grid size={{xs:12,sm:3,md:6,lg:6}} className={classes.logoContainer}>
        <h1>
          <Link to="/">
            <img src={logo} alt="Niram Healthcare" />
          </Link>
        </h1>
      </Grid>
      <Grid size={{xs:12,sm:9,md:6,lg:6}} align="right" className={classes.btnContainer}>
        <NHCButton children="sign up" color="primary" variant="contained" sx={{mr:1}} onClick={handleSignUpClick} />
        <NHCButton children="login" color="primary" variant="outlined-transparent" onClick={handleLoginClick} />
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(RegistrationHeader);
