import React, { Fragment } from "react";

import LayoutWrpper from "./LayoutWrapper";
import WhiteBgLogo from "../Header/WhiteBgLogo";

const withWhiteBgLogo = (Component) => {
  return props => (
    <LayoutWrpper>
      <WhiteBgLogo />
      <Component {...props} />
    </LayoutWrpper>
  );
};

export default withWhiteBgLogo;
