import React from "react";
import { useSelector,shallowEqual } from "react-redux";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';


import logo from "@assets/images/logo.png";
import NavigationBar from "./NavigationBar";
import LoggedInActions from "./LoggedInActions";
import LoggedOutActions from "./LoggedOutActions";
import { GlobalRoutePath } from "@router/constants";
import { useStyles } from "./styles";

const stateSelector = state => ({
  userClinics: state.clinicReducer.userClinics,
  clinicLogo:state.clinicReducer.clinicLogo,
  selectedClinicId: state.clinicReducer.selectedClinicId,
  isLoggedIn: state.userReducer.isLoggedIn
});


const AppHeader = ({ classes }) => {
  const { isLoggedIn, selectedClinicId, userClinics,clinicLogo } = useSelector(state => stateSelector(state),shallowEqual);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const getSelectedClinicLogoUrl = () => clinicLogo || null;

  const setDefaultLog = (event) => {
    event.target.src = logo;
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    ><NavigationBar/></Menu>
  )
  return (
    <AppBar className={classes.AppHeader}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoNavContainer}>
        <h1>
          <Link to={isLoggedIn ?GlobalRoutePath.LANDING.path:GlobalRoutePath.HOME.path}>
            <img src={ getSelectedClinicLogoUrl() || logo} onError={e => setDefaultLog(e)} alt="Niman Healthcare" />
          </Link>
        </h1>
      </div>
      <div className={classes.dropDownUserActionsContainer}>
        
        <div className={`${classes.sectionDesktop} mr-24`}>
          <NavigationBar />
        </div>

        {
          isLoggedIn ? <LoggedInActions /> : <LoggedOutActions />
        }
        <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
      </div>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
};

export default withStyles(useStyles)(AppHeader);
