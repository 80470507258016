import React, { Component,} from "react";

import { Provider as ReactReduxProvider } from "react-redux";
import {Amplify} from "aws-amplify";
import {Helmet} from "react-helmet";
import { CacheProvider, } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider as MUIThemeProvider } from "@mui/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { aws_config } from "./config/aws_config";
import theme from "./assets/Theme";
import GlobalRouter from "./GlobalRouter";
import GlobalLoader from "./components/GlobalLoader";
import SessionHandler from "./components/SessionHandler";
import metaDetails from "./components/common/MetaDetails";
import { initEvents } from "@utils/Events";

import './stylesheets/common.css';
import store from "./Redux/Store";

require('@utils/LoadUtility');

const gaTrackId = process.env.REACT_APP_GA_TRACKING_ID;
initEvents();
const muiCache = createCache({
  key: 'mui',
  prepend: true,
});
// Configure AWS Amplify
Amplify.configure(aws_config);

// Configurae Google Analytics
gaTrackId && ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const history = createBrowserHistory();
gaTrackId && history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {

  render() {

    return(
      <ReactReduxProvider store={store}>
      <CacheProvider value={muiCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MUIThemeProvider theme={theme}>
          {/* <DefaultHelmet /> */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaDetails.home.metaTitle}</title>
                <meta name="description" content={metaDetails.home.metaDesc}/>
                <meta name="keywords" content="Operational Analytics, Quality Analytics, Revenue Analytics, Closing Care Gaps, Effective Patient Engagement, Population Health, Revenue Cycle Management, Healthcare RPA , Clinic Efficiency, Care Management, Close Care Gaps"/>
            </Helmet>
          <GlobalRouter />
          <GlobalLoader />
          <SessionHandler/>
        </MUIThemeProvider>
        </LocalizationProvider>
        </CacheProvider>
      </ReactReduxProvider>
    );
  }

}

export default App;
