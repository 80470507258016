import React  from "react";
import { useSelector, useDispatch,shallowEqual } from "react-redux";

import warningIcon from "@mui/icons-material/Warning";
import closeIcon from "@mui/icons-material/Cancel";

import NotificationBar, {notificationBarTypes} from "../../common/NotificationBar";
import {platformCoreActions} from "../../../Redux/actionCreators";

const stateSelector = state => ({
  announcements: state.platformCoreReducer.announcements,
});

const Announcement = () => {

  const dispatch = useDispatch();
  const {announcements} = useSelector(state => stateSelector(state),shallowEqual);

  const handleClose = () => {
    dispatch(platformCoreActions.updateAnnouncementsVisibility(false));
  }

  return (
      <NotificationBar 
        message={announcements.messages.length > 0 ? announcements.messages[0].details: ""} 
        type={notificationBarTypes.WARNING} 
        showNotification={announcements.messages.length > 0 ? announcements.displayStatus : false} 
        icon={warningIcon} 
        link="Dismiss" 
        linkIcon={closeIcon}
        closeAction = {handleClose}
    />
  );
}

export default Announcement
