import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(MUITheme => ({
AdminUser:{
    flex:"1",
    padding: 24,
    position: 'relative',
    '&.p-0':{
      padding:0,
      '& .page-data-child':{
        minHeight:"calc(100vh - var(--h_height))",
      }
    },
    '&.p-1':{
      padding:1,
      '& .page-data-child':{
        minHeight:"calc(100vh - var(--h_height) - 2px)",
      }
    },
    '&.p-4':{
      padding:4,
      '& .page-data-child':{
        minHeight:"calc(100vh - var(--h_height) - 8px)",
      }
    }
  },
  AdminWraper:{
    width:"100%",
    background: MUITheme.palette.background.contentPane,
    paddingTop:"0px",
    display:"flex",
    // flexDirection: 'column',
    minHeight:"calc(100vh - var(--h_height))",
    '&.no-scroll':{
      minHeight:"calc(100vh - var(--h_height))",
      '& .page-data-child':{
        minHeight:"calc(100vh - var(--h_height))",
      }
    },
    [MUITheme.breakpoints.down("sm")]: { 
      position:"relative",
      
    },
  },
  activeClass:{
    position:"relative",
    width:"100%",
    "&::before":{
      "@media (max-width:600px)": {
        content:'""',
        position:"fixed",
        backgroundColor:"rgba(109,201,234,0.4)",
        top:"0",
        bottom:"0",
        left:"0",
        right:"0",
        zIndex:"99",
       
      },
    },
  },
  leftmenuDashboardContainer:{ 
    display: 'flex',
  },
  dashboardsMainParent:{ 
    //minHeight: 'calc(100vh - var(--h_height) - 48px)'
  },
  footerText:{
    margin: "140px 0 30px",
    background: MUITheme.palette.background.primary,
    textAlign: 'center',
    '@media(max-width:820px)':{ padding: '25px auto'}
  }
}));