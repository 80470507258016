
import AwsAmplifyApi from "../../utility/AwsAmplifyApi";
import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";


import { loaderActions, errorActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_ACTIVE_PAYERS_AND_MEASURES = "UPDATE_ACTIVE_PAYERS_AND_MEASURES";
export const UPDATE_TYPE_OF_PAYER = "UPDATE_TYPE_OF_PAYER";
export const UPDATE_MEASURES_DETAILS = "UPDATE_MEASURES_DETAILS";
export const UPDATE_YEARS_LIST = "UPDATE_YEARS_LIST";
export const UPDATE_MEASURE_VALUE = "UPDATE_MEASURE_VALUE";
export const UPDATE_IS_ACTIVE = "UPDATE_IS_ACTIVE";
export const UPDATE_FLAG = "UPDATE_FLAG";

export const setActivePayersAndMeasures = activePayersAndMeasures =>({
    type: UPDATE_ACTIVE_PAYERS_AND_MEASURES,
    payload: activePayersAndMeasures
})

export const setTypeOfPayer = typeOfPayer =>({
    type: UPDATE_TYPE_OF_PAYER,
    payload: typeOfPayer
})

export const setMeasuresDetails = (measuresDetails) => dispatch =>{
    var measures =[]
    if(measuresDetails.length > 0){
        measures = sort(measuresDetails);
    }
    dispatch({
        type: UPDATE_MEASURES_DETAILS,
        payload: measures
    })
}

export const setListOfYears = () => dispatch => {
    const currentYear = parseInt(new Date().getFullYear())
    const yearList=[];
    for(let i = currentYear - 10 ; i < currentYear+6; i++ ){
        yearList.push(i);
    }
    dispatch({
        type: UPDATE_YEARS_LIST,
        payload: yearList
    })
}

export const updateMeasureValue = (value, measureId) => dispatch =>{

    const valueDetails={
        value,
        measureId
    }
    dispatch({
        type: UPDATE_MEASURE_VALUE,
        payload: valueDetails
    })
}

export const updateFlag = flag => ({
    type: UPDATE_FLAG,
    payload: flag
})


export const updateIsActiveStatus = (status, measureId)=> dispatch =>{
    const statusDetails={
        status,
        measureId
    }
    dispatch({
        type: UPDATE_IS_ACTIVE,
        payload: statusDetails
    })
}

const sort = (measuresDetails) =>{
    var measures = measuresDetails.filter(measure => measure.status !== false)
    for(let i=0;i<measuresDetails.length; i++){
        if(measuresDetails[i].status === false){
            measures.push(measuresDetails[i]);
        }
    }
    return measures;
}

const updateErrorCode = err => dispatch => {
  dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};


//Fetch all active payers and measures details

const fecthAllActivePayersAndMeasuresApi = (clinicId, year) => async dispatch =>{
    const queryString = {
        "measure_year": year,
    }
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PAYER_MEASURE_DETAILS;

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, queryString,clinicId}));
}

export const fecthAllActivePayersAndMeasures = (selectedClinicId, year) => async dispatch =>{
    try{
        dispatch(updateErrorCode(undefined));
        dispatch(loaderActions.startAppLoader(LoaderContent.PAYER_MEASURE_DETAILS));
        const data=[];
        dispatch(setActivePayersAndMeasures(data));
        const res = await dispatch(fecthAllActivePayersAndMeasuresApi(selectedClinicId, year));
        dispatch(setActivePayersAndMeasures(res.data));
        dispatch(loaderActions.stopAppLoader());
    }catch(error){
        const activePayersAndMeasuresDetails = [];
        dispatch(loaderActions.stopAppLoader());
        dispatch(setActivePayersAndMeasures(activePayersAndMeasuresDetails))
        if(error.response.status === 403){
            dispatch(updateErrorCode(403));
        }
    }
}

//************ Fetch type of payers */

const fetchTypeOfPayersApi = (clinicId) => async dispatch => {

    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.PAYERS_DETAILS;

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

}

export const fetchTypeOfPayers = (selectedClinicId) => async dispatch => {
    try{
        //dispatch(loaderActions.startAppLoader(LoaderContent.PAYER_DETAILS));
        // const data = [];
        // dispatch(setTypeOfPayer(data));
        const res = await dispatch(fetchTypeOfPayersApi(selectedClinicId));
        dispatch(setTypeOfPayer(res.data));
        //dispatch(loaderActions.stopAppLoader());
    }catch(error){
        //dispatch(loaderActions.stopAppLoader());
        const typeOfPayer = [];
        dispatch(setTypeOfPayer(typeOfPayer));
        throw error;
    }
}

//************** Fetch measures details */

const fetchMeasuresDetailsApi = (clinicId, payerId, year) => async dispatch =>{
    const queryString = {
        "measure_year": year,
    }
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.MEASURE_DETAILS(payerId);

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, queryString,clinicId}));
}

export const fetchMeasuresDetails = (selectedClinicId, payerId, measureYear)  => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.MEASURE_DETAILS));
        const data = [];
        dispatch(setMeasuresDetails(data));
        const res= await dispatch(fetchMeasuresDetailsApi(selectedClinicId, payerId, measureYear));
        dispatch(setMeasuresDetails(res.data));
        dispatch(loaderActions.stopAppLoader());
    }catch(error){
        dispatch(loaderActions.stopAppLoader());
        const measuresDetails=[];
        dispatch(setMeasuresDetails(measuresDetails));
        throw error;
    }
}

//*************** Save Measure details */

const saveMeasuresDetailsApi = (clinicId, payerId, year, measuresDetails) => async dispatch => {
    const body = {
        'measureYear': year,
        'measureDetails': measuresDetails
    }
    const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
    const apiPath = APIPaths.UPDATE_MEASURES_DETAILS(payerId);

    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body,clinicId}));

}

export const saveMeasuresDetails = (selectedClinicId, payerId, measureYear, measuresDetails) => async dispatch =>{
    try{
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PAYERS_MEASURES_DETAILS));
        await dispatch(saveMeasuresDetailsApi(selectedClinicId, payerId, measureYear, measuresDetails));
        dispatch(loaderActions.stopAppLoader());
    }catch(error){
        dispatch(loaderActions.stopAppLoader());
        throw error;
    }
}
