import * as API from "aws-amplify/api";
export const initializeAPIOptions = (token, body, queryStringParameters, additionalHeaders) => {
  const options = { headers: { Authorization: token, ...additionalHeaders } };
  if (body) {
    options.body = body;
  }
  if (queryStringParameters) {
    options.queryStringParameters = queryStringParameters;
  }
  return options;
};

export const clinicIdHeader = (clinicId,extraData) => ({"clinic-id": clinicId,...extraData});

export const  initializeAPIOptionsWithCId = (clinicId,token, body, queryStringParameters, additionalHeaders)=> initializeAPIOptions(token, body, queryStringParameters, clinicIdHeader(clinicId,additionalHeaders));

export const setPayload = (type,payload,dataKey) => ({
  type,payload,dataKey
});
