import AwsAmplifyApi from "../../utility/AwsAmplifyApi";

import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { fetchAuthenticatedUser } from "./UserActions/loginActions";

import { loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const UPDATE_PATIENT_DETAILS="UPDATE_PATIENT_DETAILS";
export const UPDATE_PATIENT_FOLLOWUP_DETAILS="UPDATE_PATIENT_FOLLOWUP_DETAILS";
export const FILES_PROCESSED = "FILES_PROCESSED";

export const setPatientDetails = patientDetails => ({
    type: UPDATE_PATIENT_DETAILS,
    payload: patientDetails,
  });

  export const setPatientFollowUpDetails = patientFollowUpDetails => ({
    type: UPDATE_PATIENT_FOLLOWUP_DETAILS,
    payload: patientFollowUpDetails,
  });

  export const setProcessedFiles = filesProcessed => ({
    type: FILES_PROCESSED,
    payload: filesProcessed,
  });


  // *************************************************************************
  // Fetch Patient Details
  // *************************************************************************

  const fetchADTPatientsAPI = (clinicId, followUpDays) => async dispatch => {

    const apiName = APIEndpoints.NHCSERVICEAPI.name;
    const apiPath = APIPaths.ADT_PATIENT(followUpDays);

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
  };

  export const fetchADTPatients = (clinicId, followUpDays) => async dispatch => {
    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.ADT_SERVICES));

        const patientDetails = await dispatch(fetchADTPatientsAPI(clinicId, followUpDays));

        // TODO - Need to handle errors
        // if (error.code) {
        //     throw new APIError(error.message);
        // }

        dispatch(setPatientDetails(patientDetails.data));
        dispatch(loaderActions.stopAppLoader())
    } catch (error) {
        dispatch(loaderActions.stopAppLoader())
        throw error;
    }
  };

  // *************************************************************************
  // Fetch Patient Follow Up Details
  // *************************************************************************

  const fetchADTPatientFollowUpsAPI = (clinicId, ADTId) => async dispatch => {
    const apiName = APIEndpoints.NHCSERVICEAPI.name;
    const apiPath = APIPaths.ADT_PATIENT_FOLLOWUP(ADTId);
    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
  };


  export const fetchADTPatientFollowUps = (clinicId, ADTId) => async dispatch => {
    try {

        //dispatch(loaderActions.startAppLoader(LoaderContent.ADT_SERVICES));

        const patientFollowUpDetails = await dispatch(fetchADTPatientFollowUpsAPI(clinicId, ADTId));

        // TODO - Need to handle errors
        // if (error.code) {
        //     throw new APIError(error.message);
        // }

        dispatch(setPatientFollowUpDetails(patientFollowUpDetails.data));
        //dispatch(loaderActions.stopAppLoader())
    } catch (error) {
        //dispatch(loaderActions.stopAppLoader())
        const patientFollowUpDetails = [];
        dispatch(setPatientFollowUpDetails(patientFollowUpDetails));
        throw error;
    }
  };



  // *************************************************************************
  // Add Patient Follow Up Details
  // *************************************************************************

  const addADTPatientFollowUpAPI = (clinicId, ADTId, followUpdate, outcome, notes, followUpBy) => async dispatch => {

    const body = {
      "adt_id": ADTId,
      "followup_date": followUpdate,
      outcome,
      "followupby": followUpBy,
      notes
    }
    const apiName = APIEndpoints.NHCSERVICEAPI.name;
    const apiPath = APIPaths.ADT_ADD_PATIENT_FOLLOWUP;

    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body, clinicId}));
  };


  export const addADTPatientFollowUp = (clinicId, ADTId, followUpdate, outcome, notes, followUpBy) => async dispatch => {
    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.ADT_PATIENT_FOLLOWUP_ADD));

        await dispatch(addADTPatientFollowUpAPI(clinicId, ADTId, followUpdate, outcome, notes, followUpBy));

        // TODO - Need to handle errors
        // if (error.code) {
        //     throw new APIError(error.message);
        // }

        dispatch(loaderActions.stopAppLoader())
    } catch (error) {
        dispatch(loaderActions.stopAppLoader())
        throw error;
    }

  };

// *************************************************************************
// Fetch Processed ADT Files
// *************************************************************************

  const fetchADTProcessedFilesAPI = (clinicId) => async dispatch => {
    const apiName = APIEndpoints.NHCSERVICEAPI.name;
    const apiPath = APIPaths.ADT_FILES_PROCESSED;

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
  };


  export const fetchADTProcessedFiles = (clinicId) => async dispatch => {
    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.ADT_FILES_PROCESSED));

        const filesProcessed = await dispatch(fetchADTProcessedFilesAPI(clinicId));

        // if (error.code) {
        //     throw new APIError(error.message);
        // }

        dispatch(setProcessedFiles(filesProcessed.data));
        dispatch(loaderActions.stopAppLoader())
    } catch (error) {
        dispatch(loaderActions.stopAppLoader())
        throw error;
    }

  };



// *************************************************************************
// Upload ADT Input File
// *************************************************************************


  const uploadFileAPI = (fileBlob, _fileSource) => async dispatch => {
    const { token } = await dispatch(fetchAuthenticatedUser());
    let url = `${APIEndpoints.NHCCOMMONSERVICEAPI.endpoint}${APIPaths.ADT_FILE_UPLOAD}`;
    const res = await fetch(url, { method: "POST", headers: { authorization: token }, body: fileBlob });
    return await res.json();
  };

  export const uploadFile = (fileBlob, fileSource) => async dispatch => {
    try {
      dispatch(loaderActions.startAppLoader(LoaderContent.ADT_FILE_UPLOAD));
      await dispatch(uploadFileAPI(fileBlob, fileSource));
      // if (error.code) {
      //   throw new APIError(error.message);
      // }
      dispatch(loaderActions.stopAppLoader());
    } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
    }
  };
