import React, {memo} from "react";
import MUILink from "@mui/material/Link";

const AnchorLink = memo(({ label, href, newTab, ...rest }) => {
  if (newTab) {
    return (
      <MUILink href={href} title={label} target="_blank" rel="noopener" {...rest}>
        {label}
      </MUILink>
    );
  }
  return (
    <MUILink href={href} title={label} {...rest}>
      {label}
    </MUILink>
  );
});

export default AnchorLink;
