import { minHeight } from "@mui/system";

export const useStyles = MUITheme => ({
  leftMenuContainer: { 
    width: 'var(--ls_width)',//216
    minHeight: 'calc(100vh - var(--h_height))',
    padding: 0,
    position: 'relative',
    zIndex:101,
    background: MUITheme.palette.background.gradient,
    '& .left-subCtr':{
      position:'fixed',
      width: 'var(--ls_width)',
      '& .left-content':{
        height:'calc(100vh - 178px - 32px - var(--h_height))',
        overflowY:'auto',
        scrollbarColor: '#000 transparent',
        scrollbarWidth:'thin',
        '&.sm-h':{
          height:'calc(100vh - 108px - 32px - var(--h_height))',
        }
      }
    },
    '& .foldableIcon':{
      cursor:'pointer',
      position: 'relative',
      left: 283,//199
      top: 6,
      width:'2rem',
      height:'2rem',
      borderRadius: '50%',
      background: '#ffffff',
      color: 'white',
      //transform:'rotate(45deg)',
      zIndex:101,
      '& img':{
        maxWidth:36
      },
      '& svg':{
        fontSize:'2rem',
        transform:'rotate(45deg)'
      }
    },
    '&.folded':{
        marginLeft:-285,//201
        background:"#ffffff",
        '& a:hover':{ background: 'none' },
        '& .link-icon':{
          cursor:'pointer',
          position: 'relative',
          left: 256,//199
          width:'1.7em',
          height:'1.7em',
          zIndex:101,
          '&.btm-icon':{
            left: 274
          },
          '& img':{
            maxWidth:40
          }
        },
        '& .left-content':{
          overflowY:'unset !important'
        }
    },
    '& ul':{
      width: '100%',
      padding: 0,
      margin: 0,
      '& li':{ 
        listStyle: 'none',
        border:'none',
        '& a':{
          boxSizing: 'border-box',
          width: '100%',
          padding: '0 10px 0 20px',
          display: 'flex',
          alignItems:'center',
          color: 'white',
          fontWeight:700,
          textTransform:'uppercase',
          textDecoration:'undderline',
          fontFamily: MUITheme.typography.fontFamily.primary,
          fontSize: 14,
          lineHeight: '30px',
          '&:hover':{ background: MUITheme.palette.background.darkBlue },
          '& .svgIcon':{
            width:'1.35em',
            height:'1.35em'
          }
        }
      },
      
    },
    '& .MuiAccordionSummary-root':{
      minHeight:48
    },
    '& .MuiAccordionSummary-content':{
      fontWeight:700,
      color:'white',
      fontSize:16,
      '&.Mui-expanded':{
        margin:'6px 0'
      }
    },
    '& .MuiAccordionSummary-expandIconWrapper svg':{
      color:'white',
      fontSize:'2rem'
    },
    '& .MuiExpansionPanel-root.Mui-expanded':{ 
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      margin: 0
    },
    '& .MuiExpansionPanel-root.Mui-expanded:before':{ opacity: 1 },
    [MUITheme.breakpoints.down("sm")]: { display: "none", },
    '& .search':{
      margin:'8px 16px',
      '& .MuiInputBase-root':{
        paddingRight:8,
        color:'white'
      },
      '& .MuiInputBase-input':{
        padding:8
      },
      '& .MuiOutlinedInput-notchedOutline':{
        
        borderColor:'rgb(250 246 246 / 83%)'
      },
      '& .Mui-focused':{
        '& .MuiOutlinedInput-notchedOutline':{
          borderWidth:1,
          borderColor:'rgb(250 246 246) !important'
        }
      },
      '& .MuiInputAdornment-root':{
        cursor:'pointer',
        color:'rgb(250 246 246 / 83%)'
      }
    }
  },
  mainMenuesContainer:{
    '& > li':{
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(19, 107, 214, .5)',
      '& > span':{
        padding: '0 16px 10px',
        marginTop: 16,
        display: 'inline-block',
        color: 'white',
        fontFamily: MUITheme.typography.fontFamily.primary,
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px',
      },
      '& .heading':{
        fontSize:16
      }
    }
  },
  subMenuesContainer:{
    '& li':{
      '& > a':{
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        alignItems:'center',
        fontFamily: MUITheme.typography.fontFamily.primary,
        fontSize: 14,
        fontWeight:700,
        textDecoration: 'none',
        padding: '0 9px 0 32px',
        color: MUITheme.palette.text.links,
        lineHeight: '48px',
        '&:hover':{ background: MUITheme.palette.background.darkBlue }
      }
    }
  },
  activeMenu: {
    background: MUITheme.palette.background.darkBlue,
    fontWeight: 600,
  },

  root: {
    flexGrow: 1,
    '& .MuiListItemIcon-root':{ color: MUITheme.palette.text.links },
    '& .MuiSvgIcon-root':{ color: MUITheme.palette.text.links },
    '& .MuiListItem-root':{
      '&:hover':{
        background: MUITheme.palette.background.darkBlue,
        '& .MuiListItemIcon-root':{            
          color: MUITheme.palette.background.secondary,
          fontWeight: 'bold'
        }
      }
    }
  },
  userProfileContainer: { textAlign: 'left' },
  nested: { paddingLeft: MUITheme.spacing(4)
  },
  userDetailsContainer: {
    padding: MUITheme.spacing(3),
    marginLeft: "0px",
    color: MUITheme.palette.text.secondary,
    borderRadius:"4px",
    "& h4": {
      fontWeight: "300",
      marginBottom:"20px",
    }, 
  },

  userHistoryContainer: {
      marginLeft: '40px',
      color: MUITheme.palette.text.secondary,
      paddingBottom:"40px",
      "& h4": {
        color: MUITheme.palette.text.black,
        fontWeight: "300",
        marginBottom:"20px",
        },   
  },
  UserText:{
    borderBottom:"1px solid #EDF0F2",
     clear: "both",
     display: "table",
     width:"100%",
  },
  leftText:{
    float:"left",
  },
  rightText:{
    float:"right"
  },
  dataContainer: {
      display: "inline-block",
      marginLeft:"20px",
      "& .MuiInputBase-root":{
        lineHeight:"27px",
        paddingBottom: "6px",
       fontSize:"20px",
        color: MUITheme.palette.text.primary
      },
      "& .MuiInput-underline":{
        "&::before":{
          borderBottom:"1px solid #E2F3FA",
        },
      },
  },
  AdminWraper:{
    width:"100%",
    paddingTop:"0px",
    display:"flex",
    minHeight:"100vh",
  },
  AdminLeftmenu:{
    background: MUITheme.palette.background.gradient,
    transition:" all 0.4s ease-in-out 0s",
    "& .MuiTypography-body1":{
      fontSize:"16px",
      color: MUITheme.palette.text.links,
    },
    "@media (max-width:600px)": {
      position: "absolute",

  
    },
  },
  AdminUser:{
    flex:"1",
    padding:"40px",
    position: 'relative',
    width: "90%",
    [MUITheme.breakpoints.down("sm")]: {width:"90%"},
    
  },
  ProfileBG:{
    border:"3px solid #F8C8D5",
    width:"130px",
    height:"130px",
    borderRadius:"100px",
    textAlign:"center",
    verticalAlign:" middle",
    display:"table-cell",
    marginTop:"25px",  
    "& img":{
      width:"80px",
      height:"100px",
      textAlign:"center",
    },   
  },
  firstName:{
    width:"50%",
    padding: "8px 0px",
    fongtSize:"14px",   
  },
  email:{ padding: "8px 0px" },
  phoneNumber:{ padding: "8px 0px" },
  Address:{
    width:"100%",
    padding: "8px 0px",
    borderRight:"0px",
    borderLeft:"0px",
    borderTop:"0px",
    outline:"none",
 
    "&::focus":{
      outline:"none",
    },
  },

  editbtn:{
    marginTop:"20px",
    float:"right",
    "& button":{
      border: "1px solid #CD5F79",
      borderRadius: "6px",
      padding:"13px 48px 11px;"
    },
  },
  logoContainer:{
    marginTop:"14px",
    margin:"auto",
    textAlign:"center",
    "& img":{
      width:"72%",
      marginLeft:"15px",
      "@media (max-width:960px)": {
        display:"none",
      },
    },
  },
  AdminShortLeftMenu:{
    flex:"1",
    maxWidth:"70px",
    background: "#6dc9ea",
    minHeight:"100%",
    flexShrink: "0",
    whiteSpace: "nowrap",
    overflowX:"hidden",
    marginTop:"-60px",
    transition:" all 0.4s ease-in-out 0s",
    [MUITheme.breakpoints.down("sm")]: {maxWidth:"200px", },
    "@media (max-width:600px)": {
      maxWidth:" 200px",
      position: "absolute",
      marginTop:" 0px",
      zIndex:"999"  
    },  
     "& .MuiTypography-body1":{
      fontSize:"16px",
      color:"#000",
    },
  },
 listItem:{ background: 'red' },
 expansionPanel: {
  background: 'none',
  boxShadow:'none',
  '& .MuiExpansionPanelSummary-content': { 
    margin: 0,
    '& span':{
      padding: '0 15px',
      color: MUITheme.palette.text.black,
      fontFamily: MUITheme.typography.fontFamily.secondary,
      fontSize: 14,
      fontWeight: '600',
      lineHeight: '18px'
    },
  },
  '& .MuiExpansionPanelSummary-root':{ padding: 0 },
  '& .MuiExpansionPanelSummary-root.Mui-expanded':{ minHeight: 48 },
  '& .MuiExpansionPanelSummary-content.Mui-expanded':{ margin: 0 },
  '& .MuiExpansionPanelSummary-expandIcon':{
    padding: 0,
    position: 'absolute',
    top: '50%',
    right: 20,
    transform: 'translateY(-50%)',
    '& svg':{ color: '#222026' }
  },
  '& .MuiExpansionPanelDetails-root':{ padding: 0 },
  '& .MuiIconButton-label':{ padding: 0 }
 },
 bottomSection:{
  width: '100%',
  //padding: '16px 0px 16px 16px',
  display: 'flex',
  flexDirection:'column',
  //alignItems: 'center',
  justifyContent: 'center',
  //position: 'absolute',
  bottom: 0,
  '& ul':{
    padding:'12px 0 4px',
    '& li':{
      border:'none'
    },
    '& a':{
      padding:'0 6px !important',
      textTransform:'capitalize !important'
    },
    '& .btm-icon':{
      width:'1.35em',
      height:'1.35em'
    },
    '& .empty-div':{
      lineHeight: '32px',
      padding:'0 6px',
    }
  },
  '&.sm-h':{
    '& ul':{
      display:'flex',
      justifyContent: 'center',
      padding:'12px 0',
      '& .empty-div':{
        display:'none'
      },
      '& li a':{
        lineHeight:'20px',
        padding:'0 6px !important',
        borderRight:'2px solid #ffffff',
        '& .btm-icon':{
          display:'none'
        }
      },
      '& li:last-child a':{
        border:'none'
      }
    }
  },
  '& .logoContainer':{
    display:'flex',
    margin:'12px 0',
    justifyContent:'center',
    //padding:12,
    background:'white'
  }
 },
 helpCenterTextContainer: {
  width: '100%',
  padding: '15px 0px',
  borderTop: '1px solid #fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  '& svg': { 
    paddingRight: 5,
    color: MUITheme.palette.text.white.primary
  },
  '& a':{
    color: MUITheme.palette.text.white.primary,
    fontSize: 14,
    letterSpacing: 0.5,
    lineHeight: '19px',
    textAlign: 'center',
    textDecoration: 'none'
  }
 },
 toggleBtn:{
   padding:"10px 20px",
   color: MUITheme.palette.text.white.primary,
   cursor: 'pointer',
   '& selected':{
     color:MUITheme.palette.text.black
   }
 },

});