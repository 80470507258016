
import {GlobalRoutePath} from "@router/constants";

const basePath = GlobalRoutePath.LEAD_GENERATION.path;
const EMRSelection =  /* #__LOADABLE__ */() => import("@lead-generation/EMR");
const Services =  /* #__LOADABLE__ */() => import("@lead-generation/Services");
const ClinicInfo =  /* #__LOADABLE__ */() => import("@lead-generation/ClinicInfo");
const ThanksPage =  /* #__LOADABLE__ */() => import("@lead-generation/ThanksPage");
const fullPath = path => `${basePath}/${path}`;

export const LeadGenerationRoutes = {
  DEFAULT: {
    name: "default",
    path:"",
    fullPath: fullPath(""),
    exact: true,
    loadablComp:ClinicInfo,
  },
  LEADGEN_CLINIC_INFO: {
    name: "clinic_info ",
    path:"clinicinfo",
    fullPath: fullPath("clinicinfo"),
    loadablComp:ClinicInfo,
  },
  LEADGEN_EMRS: {
    name: "emr_selection",
    path:"emrs",
    fullPath: fullPath("emrs"),
    loadablComp:EMRSelection,
  },
  LEADGEN_SERVICES: {
    name: "services",
    path:"services",
    fullPath: fullPath("services"),
    loadablComp:Services,
  },
  LEADGEN_THANKSPAGE: {
    name: "thanksPage",
    path:"thanks",
    fullPath: fullPath("thanks"),
    loadablComp:ThanksPage,
  },
  
};
