import React, { useEffect,useState,forwardRef,useImperativeHandle } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from "../Button";


const Confirm = forwardRef((
  {
    open,
    className='',
    maxWidth="xs",
    maxHeight=450,
    confirmText='Confirm',
    cancelText='Cancel',
    handleCancel,
    handleConfirm,
    title="Confirm",
    content
  },ref)=>{
    const [state,setState] = useState({open:!!open});
    const toggle = (open,opt={})=>{
      setState({...opt,open});
    }
    const onClose = ()=>{
      const cb = state.handleCancel || handleCancel;
      cb && cb();
      toggle(false);
    }
    const onConfirm = (e)=>{
        const cb = state.handleConfirm || handleConfirm;
        cb && cb(e);
        toggle(false);
    }
    useEffect(()=>{
      state.open!=open && toggle(open);
    },[open]);
    useImperativeHandle(ref, () => {
        return {
          toggle
        };
      }, []);
    return(<Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight:(state.maxHeight || maxHeight)} }}
        maxWidth={state.maxWidth || maxWidth}
        keepMounted
        open={state.open}
        className={`confirm-dialog ${state.className || className}`}
      >
        {(state.title || title) && <DialogTitle sx={{textAlign:'center',fontWeight:700,p:2.5}}>{state.title || title}</DialogTitle>}
        {(state.content || content) && <DialogContent sx={{px:2,py:5}}>
            {state.content || content}
        </DialogContent>}
        <DialogActions sx={{px:2,py:3,justifyContent:'center'}}>
          {(state.cancelText || cancelText) && <Button autoFocus onClick={onClose} variant="outlined">
            {state.cancelText || cancelText}
          </Button>}
          <Button color="primary" onClick={onConfirm}>{state.confirmText || confirmText}</Button>
        </DialogActions>
      </Dialog>)
});

export default Confirm;