import React,{useContext,useRef} from "react";
import { useSelector, useDispatch,shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { withStyles } from "@mui/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoodIcon from "@mui/icons-material/Mood";
import SettingsIcon from '@mui/icons-material/Settings';
import { Modal } from "@mui/material";

import {PermissionContext} from "../../../PermissionContext";
import { useStyles } from "./styles";
import UserProfileCard from "../../../common/UserProfileCard";
import { loginActions } from "@redux/actionCreators/UserActions";
import {GlobalRoutePath} from "@router/constants";
import SvgIcon from "@common/SvgIcon";
import {SHOW_GLOBAL_ALERT} from "@utils/Events/constants";


const UserProfilePopUp = ({ classes, show, handleClose }) => {

  const navigate = useNavigate();
  const permissions = useContext(PermissionContext);
  const settingPrv = "SERVICE SUBSCRIPTION";
  const { firstName, profilePicURL} = useSelector(state => ({
    firstName: state.userReducer.firstName,
    profilePicURL: state.userReducer.profilePicURL,
  }),shallowEqual);
  const dispatch = useDispatch();
  const confirmLogout = (e)=>{
    e.preventDefault();
    handleClose();
    _app.events.trigger(SHOW_GLOBAL_ALERT,{title:"Are you sure you want to logout?",cancelText:'Cancel', okText:"Yes, log me out",onOkClick:handleSignout})
  }
  const handleSignout = (e) => {
    e.preventDefault();
    dispatch(loginActions.signout);
    navigate(GlobalRoutePath.HOME.path);
  };

  return (<>
    <Modal open={true} onClose={handleClose}>
      <div className={classes.UserProfilePopUpContainer}>
        <UserProfileCard profilePicURL={profilePicURL} firstName={firstName} />
        <ul className={classes.userProfileMenuList}>
          <li>
            <Link to={GlobalRoutePath.USER_PROFILE.path}>
              <SvgIcon icon="/static/images/my-profile.svg" size="extra-large" className="mr-4"/>
              <span className="text">My Profile</span>
            </Link>
          </li>
          <li>
            <Link to={GlobalRoutePath.SETTINGS.path+(permissions.includes(settingPrv)?"":"/helpcenter")}>
              <SvgIcon icon="/static/images/settings.svg" size="extra-large" className="mr-4"/>
              <span className="text">Settings</span>
            </Link>
          </li>
          <li>
            <a href="#" onClick={confirmLogout}>
              <SvgIcon icon="/static/images/logout.svg" size="extra-large" className="mr-4"/>
              <span className="text">Logout</span>
            </a>
          </li>
        </ul>
      </div>
      
      
    </Modal>
    </>
  );
};

export default withStyles(useStyles)(UserProfilePopUp);
