import React, { lazy } from 'react';
import {GlobalRoutePath} from "@router/constants";

const ServiceSubscription =  /* #__LOADABLE__ */() => import("@settings/ServiceSubscription");
const PayersAndMeasures =  /* #__LOADABLE__ */() => import("@settings/PayersAndMeasures");
const ClinicManagement =  /* #__LOADABLE__ */() => import("@settings/ClinicManagement");
const UserManagement =  /* #__LOADABLE__ */() => import("@settings/UserManagement");
const CustomizeApp =  /* #__LOADABLE__ */() => import("@settings/CustomizeApp");
const UserRoleManagement =  /* #__LOADABLE__ */() => import("@settings/UserRoleManagement");
const HelpCenter =  /* #__LOADABLE__ */() => import("@components/HelpCenter");
const DataImport =  /* #__LOADABLE__ */() => import("@settings/DataImport");
const SmartScheduler =  /* #__LOADABLE__ */() => import("@settings/SmartScheduler");

const fullPath = path => `${GlobalRoutePath.SETTINGS.path}/${path}`;
const SettingsRouteConfig = {
  DEFAULT: {
    name: "default",
    path: "/",
    exact: true,
    loadablComp: ServiceSubscription,
  },
  SERVICE_SUBSCRIPTION: {
    name: "service_subscription",
    path: "servicesubscription",
    loadablComp: ServiceSubscription,
  },
  PAYERS_MEASURES: {
    name: "payers_measures",
    path: "payersandmeasures",
    loadablComp: PayersAndMeasures,
  },

  CLINIC_MANAGEMENT: {
    name: "clinic_management",
    path: "clinicmanagement",
    loadablComp: ClinicManagement,
  },
  HELP: {
    name: "help_center",
    path: "helpcenter",
    loadablComp: HelpCenter
  },
  USER_MANAGEMENT: {
    name: "user_management",
    path: "usermanagement",
    loadablComp: UserManagement
  },
  CUSTOMIZE_APP: {
    name: "customize_app",
    path: "customizeapp",
    loadablComp: CustomizeApp
  },
  USER_ROLE_MANAGEMENT: {
    name: "user_role_management",
    path: "userrolemanagement",
    loadablComp: UserRoleManagement
  },
  DATA_IMPORT: {
    name: "data_import",
    path: "dataimport",
    loadablComp: DataImport
  },
  SMART_SCHEDULER: {
    name: "smart_scheduler",
    path: "smartscheduler",
    loadablComp: SmartScheduler
  }
};
Object.values(SettingsRouteConfig).forEach(r=>r.fullPath = fullPath(r.path));
export const SettingsRoutes = SettingsRouteConfig;

