import React, {memo} from "react";
import TextField from "@mui/material/TextField";

const textType = (wrapType)=>{
    let type = 'text';
    switch(wrapType){
        case 'number':type = 'text';break;
        default:type = wrapType;
    }
}
const TextFieldWrapper = memo(({
    wrapType = "text",
    validationOptions = {},
    onChange,
    ...restOpts
})=>{
    const validateNum = (value)=>{
        let v = /^\d*$/.test(value);
        v && validationOptions.maxLength && (v = (value+'').length<= validationOptions.maxLength)
        v && validationOptions.max && (v= value <= validationOptions.max)
        return v;
    }
    const changeHandler = (e)=>{
        switch(wrapType){
            case 'number':validateNum((e.target.value || '')) && onChange && onChange(e);break;
            default:onChange && onChange(e);
        }
    }
    return <TextField {...restOpts} type={textType(wrapType)} onChange={changeHandler}/>
});

export default TextFieldWrapper;