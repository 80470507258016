import * as API from "aws-amplify/api";
import { fetchAuthenticatedUser,appContext } from "../Redux/actionCreators/UserActions/loginActions";

export const apiOptions = ({token, body, queryString, headers={},clinicId,excludeClinicHeader}) => {
  //console.log('appContext',appContext);
  const cid = clinicId || appContext.cid;
  const additionalHeaders = cid && !excludeClinicHeader? clinicIdHeader(cid,headers):headers;
  const options = { headers: { Authorization: token, ...additionalHeaders } };
  if (body) {
    options.body = body;
  }
  if (queryString) {
    options.queryParams = Object.keys(queryString).reduce((fob,k)=>{!(queryString[k]===''|| queryString[k]===undefined || (queryString[k].constructor.name == 'Array' && !queryString[k].length)) && (fob[k] = queryString[k]);return fob;},{});
  }
  return options;
};
const parseError = (error)=>{
  const parsedError = {response:{status:error.$metadata?.httpStatusCode},error};
  try{
    if(error._response){
      const body = error._response.body?JSON.parse(error._response.body):{};
      parsedError.response = {status:error._response.statusCode,data:body}
    }
  }
  catch{

  }
  return parsedError;
}
export const clinicIdHeader = (clinicId,extraData={}) => ({"clinic-id": clinicId,...extraData});

export default class AwsAmplifyApi{
  static get({apiName, apiPath,clinicId,queryString,headers,...others}){
    return AwsAmplifyApi._send({apiName, path:apiPath,clinicId,queryString,headers,...others});
  }

  static del({apiName, apiPath,clinicId,queryString,headers,...others}){
    return AwsAmplifyApi._send({method:'del',apiName, path:apiPath,clinicId,queryString,headers,...others});
  }

  static post({apiName, apiPath,body,queryString,clinicId,headers,...others}){
    return AwsAmplifyApi._send({method:'post',apiName, path:apiPath,clinicId,body,queryString,headers,...others});
  }

  static put({apiName, apiPath,body,queryString,clinicId,headers,...others}){
    return AwsAmplifyApi._send({method:'put',apiName, path:apiPath,clinicId,body,queryString,headers,...others});
  }

  static patch({apiName, apiPath,body,queryString,clinicId,headers,...others}){
    return AwsAmplifyApi._send({method:'patch',apiName, path:apiPath,clinicId,body,queryString,headers,...others});
  }

  static _send({method='get',apiName, path,body,queryString,clinicId,headers,...others}){
    return async dispatch => {
      const { token } = await dispatch(fetchAuthenticatedUser());
      const options = apiOptions({token,body,queryString,clinicId,headers,...others});
      try{
        const res = await API[method]({apiName, path, options});
        const {body,headers}  = await res.response;
        let finalResponse = body;
        if(headers["content-type"] == "application/json") {
          finalResponse = await body.json();
        }
        else if(body.blob){
          finalResponse = await body.blob();
        }
        else if(body.text){
          finalResponse = await body.text();
        }
        console.log('response',finalResponse);
        return finalResponse;
      }
      catch(error){
          const perror = parseError(error);
          console.log('AwsAmplifyApi Error',perror);
          throw perror;
      }
      return null;
    }
  }
}
