import React, { Fragment } from "react";
import RegistrationHeader from "../Header/RegistrationHeader";

import LayoutWrpper from "./LayoutWrapper";
const withRegistrationHeader = (Component,opts) => {
  const childComponent =  props => (
	  <LayoutWrpper>
  	  <RegistrationHeader {...opts}>
    	  <Component {...props} />
      </RegistrationHeader>
    </LayoutWrpper>  
  );
    return childComponent;
};

export default  withRegistrationHeader;
