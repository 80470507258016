import React, { Fragment,useEffect,useRef } from "react";

import * as errorActions from "@redux/actionCreators/ErrorActions";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import ToastMessage from "@common/ToastMessage";
import AlertDialog from "@common/AlertDialog";
const LayoutWrpper =({childRoute=false,pageRoute=true,children})=>{
    const dispatch = useDispatch();
    const errorRef = useRef(null);
    const error = useSelector(state=>state.errorReducer.errorCode,shallowEqual);
    const resetError = ()=>{
        //console.log('unmount LayoutWrpper',error,errorRef.current);
        !!errorRef.current && dispatch(errorActions.resetCustomAuthenticationErrorCode());
    }
    useEffect(()=>{
        errorRef.current = error;
    },[error])
    useEffect(()=>{
        //console.log('location.pathname',location.pathname);
        resetError();
    },[location.pathname])
    useEffect(()=>{
        //console.log('init LayoutWrpper',childRoute,pageRoute);
        return ()=>{
            resetError();
        }
    },[]);
    //console.log('error----',error);
    return<Fragment>
        {children}
        {!childRoute && <><ToastMessage global show={false}/>
        <AlertDialog global open={false}/></>}
    </Fragment>

}

export default LayoutWrpper;