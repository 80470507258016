import React from "react";
import Image from '../Image';
import Icon from '@mui/material/Icon';
import { makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    svgIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.pointer':{
            cursor:'pointer'
        },
        '& img':{
            maxWidth:24
        },
        '&.s-150x':{
            width:'1.5em',
            height:'1.5em',
            '& img':{
                maxWidth:32
            }
        },
        '&.s-200x':{
            width:'2em',
            height:'2em',
            '& img':{
                maxWidth:40
            }
        },
        '&.m-4':{
            margin:4
        },
        '&.mr-4':{
            marginRight:4
        },
        '&.ml-4':{
            marginLeft:4
        },
        '&.m-6':{
            margin:6
        },
        '&.mr-6':{
            marginRight:6
        },
        '&.ml-6':{
            marginLeft:6
        },
        '&.m-8':{
            margin:8
        },
        '&.mr-8':{
            marginRight:8
        },
        '&.ml-8':{
            marginLeft:8
        },
        
  }
}));

const sizeClass = {normal:'s-100x',large:'s-150x','extra-large':'s-200x'}
const SvgIcon = ({icon,className='',size="large",onClick=null,...others})=>{
    const classes = useStyles();
    return icon?<Icon className={`${classes.svgIcon} svgIcon ${className} ${sizeClass[size] || ''} ${onClick?'pointer':''}`} onClick={onClick} {...others}><Image src={icon}/></Icon>:''

}

export default SvgIcon;