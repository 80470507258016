import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { useStyles } from "./styles";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import SvgIcon from "../../../common/SvgIcon";

const NavbarLink = props => {
  const { activeLinks, label, openInNewTab, to, type,icon } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const isActive = (!!activeLinks && activeLinks.some(l=>location.pathname.includes(l)))

  return (
    type==="menu"?
    <nav>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {label}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem containerElement={
          <NavLink
            children={label}
            to={to}
            target={openInNewTab ? "_blank" : ""}
            rel={openInNewTab ? "noreferrer noopener" : ""}
            className={`${classes.navLink} ${isActive && classes.navLinkActive}`}
          />
        }>
          ADT Automation
        </MenuItem>
        <MenuItem onClick={handleClose}>Service 2</MenuItem>
        <MenuItem onClick={handleClose}>Service 3</MenuItem>
      </Menu>
    </nav>
    :
    <NavLink
      children={<><SvgIcon icon={icon} className="mr-4"/> {label}</>}
      to={to}
      target={openInNewTab ? "_blank" : ""}
      rel={openInNewTab ? "noreferrer noopener" : ""}
      className={`${classes.dFlex} ${classes.navLink} ${isActive && classes.navLinkActive}`}
    />
  );
};

NavbarLink.propTypes = {
  activeLinks: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  openInNewTab: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NavbarLink;
