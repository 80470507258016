import * as CONST from "./constants";

const __events = {};

const EventBase = {
    key: -1,
    currentCtxKey: -1,
    keyContext: {},
    subscribers: {},
    windowEvents: [CONST.WIN_CLICK, CONST.WIN_RESIZE, CONST.WIN_SCROLL, CONST.WIN_TOUCH_MOVE],
    registeredEvents: [],
    subscribe: function (subscriberInfo, context, ctxKey) {
        const key = context && context[subKey] ? Number(context[subKey]) : ++this.key;
        const oldC = this.subscribers[key];
        oldC && (subscriberInfo = Object.assign(Object.assign({}, oldC), subscriberInfo));
        this.subscribers[key] = subscriberInfo;
        this.registerEvents(subscriberInfo);
        if (context) {
            this.keyContext[key] = { ctx: context, ctxKey: (ctxKey || this.currentCtxKey), key: key };
            context[subKey] = key;
        }
        return key;
    },
    publish: function (args, eventName) {
        Object.keys(this.subscribers).forEach(key => {
            let sub = this.subscribers[Number(key)];
            try {
                if (sub && sub[eventName]) {
                    sub[eventName](...args);
                }
            }
            catch (ignore) {
                console.log('error in publishing event ' + eventName + ' ' + sub + ' error:: ' + ignore);
            }
        });
    },
    generateNewCtxKey: function (removeCurrent) {
        removeCurrent && this.unsubscribeUnMoutedCTx(false, this.currentCtxKey);
        this.currentCtxKey = new Date().getTime();
        return this.currentCtxKey;
    },
    trigger: function (eventName, ...arg) {
        setTimeout(() => this.publish(arg,eventName), 0);
        return eventName;
    },
    hasEvent: function (key) {
        return !!this.subscribers[key];
    },
    unsubscribeEvent: function (key, eventId) {
        if (this.subscribers[key]) {
            delete this.subscribers[key][eventId];
            delete this.keyContext[key][eventId];
            return this.keyContext[key][eventId];
        }
        return null;
    },
    unsubscribe: function (key) {
        //console.log('unsubscribe => ' + CacheUtils.stringify(Object.keys(this.subscribers[key])));
        delete this.subscribers[key];
        this.keyContext[key] && this.keyContext[key].ctx && (this.keyContext[key].ctx[subKey] = null);
        delete this.keyContext[key];
        return this.keyContext[key];
    },
    unsubscribeUnMoutedCTx: function (allCtx, ctxKey) {
        Object.keys(this.subscribers).forEach(key => {
            try {
                let context = this.keyContext[Number(key)];
                if (context && (allCtx || (context.ctxKey == ctxKey) || (context.ctx.updater && !context.updater.isMounted(context)))) {
                    //console.log('----------removed ctxt----------');
                    this.unsubscribe(context.key);
                }
            }
            catch (ignore) { }
        });
        //genNewCtx && this.generateNewCtxKey();
    },
    registerEvents: function (events) {
        for (var key in events) {
            this.registerEvent(key);
        }
    },
    registerEvent: function (eventName) {
        if (eventName && !this.registeredEvents.includes(eventName) && this.windowEvents.includes(eventName)) {
            const self = this;
            window.addEventListener(eventName, function (event) {
                let arg = [event];
                commonEvents[eventName] && arg.push(commonEvents[eventName]());
                self.publish(arg, eventName);
            });
            this.registeredEvents.push(eventName);
        }
    }
};
const Events = Object.seal(EventBase);
export function initEvents() {
    if (window._app != undefined && window._app.events)
        return;
    const app = { u:{luid:'-'},events: Object.create(Events) };
    window._app == undefined && (window._app = Object.freeze(app));
}

//exports.initEvents = initEvents;
export default Events;
