import React,{useEffect} from "react";
import { Route, Navigate } from "react-router-dom";

import withRegistrationHeader from "@layout/withRegistrationHeader";
import withHeaderAndFooter from "@layout/withHeaderAndFooter";
import withPlainHeader from "@layout/withPlainHeader";
//import withLeftNavHeader from "@layout/withLeftNavHeader";
import withWhiteBgLogo from "@layout/withWhiteBgLogo";
import LayoutWrapper from "@layout/LayoutWrapper";
const LAYOUTS = {
  withRegistrationHeader,
  withHeaderAndFooter,
  withPlainHeader,
  withWhiteBgLogo
}
const withLayout = (layout='default',Component,opt)=>{
  const args = [Component];
  opt && args.push(opt);
  const LayComponent = LAYOUTS[layout]?LAYOUTS[layout](...args):Component;
  return <LayComponent/>
}
export const LoginRoute = ({ component,hasChildRoutes, onRouteChange,isLoggedIn, path, redirectTo,layout,layoutOpt,...props}) => {
  if (isLoggedIn) {
    !hasChildRoutes && onRouteChange(path,isLoggedIn);
    return withLayout(layout,component,layoutOpt);
  }
  //console.log('Redirect to home',isLoggedIn,path);
  return <Navigate to={{pathname: redirectTo, state:{from: path} }} />;
};

export const NonLoginRoute = ({ component, onRouteChange,hasChildRoutes, path, isLoggedIn, redirectTo,layout,layoutOpt,...props}) => {
  if (!isLoggedIn) {
    !hasChildRoutes && onRouteChange(path,isLoggedIn);
    return withLayout(layout,component,layoutOpt);
  }
  //console.log('Redirect to Login',isLoggedIn,path);
  return <Navigate to={{pathname: redirectTo, state:{from: path} }} />;
};
export const ChildRoute = ({component:Comp, onRouteChange,isLoggedIn,path,...props})=>{
  onRouteChange(path || location.pathname,isLoggedIn);
  return <LayoutWrapper childRoute pageRoute={false}><Comp {...props}/></LayoutWrapper>
}
const PrivateRoute = ({ authRequired,...props})=>{
  const Comp = authRequired?LoginRoute:NonLoginRoute;
  return <Comp {...props}/>
}
export default PrivateRoute;
