import React, { Fragment } from "react";

import LayoutWrpper from "./LayoutWrapper";
import PlainHeader from "../Header/PlainHeader";

import Announcement from "../Header/Announcement";
//import Footer from "../common/Footer";

const withPlainHeader = (Component) => {
  return props => (
    <LayoutWrpper>
    	<Announcement />
      <PlainHeader />
      <Component {...props} />
      {/* <Footer /> */}
    </LayoutWrpper>
  );
};

export default withPlainHeader;
