import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../common/Button";
import {GlobalRoutes} from "../../../GlobalRouter/Routes";

const LoggedOutActions = () => {

  const navigate = useNavigate();

  return (
    <Fragment>
      <Button
        children="login"
        color="primary"
        variant="contained"
        onClick={() => navigate(GlobalRoutes.LOGIN.path)} />
    </Fragment>
  );
};

export default LoggedOutActions;
