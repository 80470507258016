import React, { lazy } from 'react';
import {GlobalRoutePath} from "@router/constants";
const SpeechToText = /* #__LOADABLE__ */() => import("@ai-services/SpeechToText");
const CoPilot = /* #__LOADABLE__ */() => import("@ai-services/CoPilot");
const EFax = /* #__LOADABLE__ */() => import("@ai-services/EFax");

const fullPath = path => `${GlobalRoutePath.AI_SERVICES.path}/${path}`;

export const AIServiceRoutes = {
  DEFAULT: {
    name: "default",
    path: "/",
    fullPath:fullPath(""),
    exact: true,
    loadablComp: SpeechToText,
  },
  SPEECH_TO_TEXT: {
    name: "service_subscription",
    path: "speech-to-text",
    fullPath:fullPath("speech-to-text"),
    loadablComp: SpeechToText,
  },
  CO_PILOT: {
    name: "payers_measures",
    path: "copilot",
    fullPath:fullPath("copilot"),
    loadablComp: CoPilot,
  },

  E_FAX: {
    name: "clinic_management",
    path: "e-fax",
    fullPath:fullPath("e-fax"),
    loadablComp: EFax,
  }
};
