import { APIEndpoints } from "./APIEndpoints";

let endpoints = {};

Object.values(APIEndpoints).forEach(value => endpoints[value.name] = value);

export const aws_config = {
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      signUpVerificationMethod: 'code'
    }
  },

  API: {
    REST:{
      ...endpoints
    }
  },
};
