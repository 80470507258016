import * as API from "aws-amplify/api";

import { APIEndpoints, APIPaths,getNonProxyUrl } from "@config/APIEndpoints";
import AwsAmplifyApi from "../../../utility/AwsAmplifyApi";
import { setUserAttributes } from "./loginActions";
import { loaderActions } from "../";
import { LoaderContent } from "../../../utility/constants/LoaderContent";
import { CustomAPI } from "@utils/CustomAPI";
import { fetchAuthenticatedUser,appContext } from "@redux/actionCreators/UserActions/loginActions";

export const UPDATE_PROFILE_PIC="UPDATE_PROFILE_PIC";
export const SET_USER_PERMISSIONS_BASED_ON_CLINIC = "SET_USER_PERMISSIONS_BASED_ON_CLINIC";


const updateProfilePic = (profilePicURL) => ({
    type: UPDATE_PROFILE_PIC,
    payload: profilePicURL,
})

const updateLoginedUserPermission = (permissions) =>({
    type: SET_USER_PERMISSIONS_BASED_ON_CLINIC,
    payload: permissions,
})

// imageContent in base64 format and assuming the content is PNG format
const profilePicUploadAPI = (body) => async dispatch =>{
    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_PROFILE_PIC;
    const headers = {
        "Content-Type":"image/png"
    }
    return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body,headers}));
}

const profilePicUploadAPI2 = (body) => async dispatch =>{
    const { token } = await dispatch(fetchAuthenticatedUser());
    const api = APIEndpoints.NHCUSERAPI.endpoint;
    //console.log('api',api,getNonProxyUrl(api));
    const apiPath = APIPaths.USER_PROFILE_PIC;
    const headers = {
        "Content-Type":"image/png",
        Authorization: token,
        "clinic-id": appContext.cid
    }
    return await CustomAPI.post(`${api}${apiPath}`,{body,headers});
}

export const profilePicUpload = (imageData,loaderContent) => async dispatch => {

    try {
        dispatch(loaderActions.startAppLoader(loaderContent));
        const response = await dispatch(profilePicUploadAPI2(imageData));
        if(response.error.code) {
            throw response.error
        }
        await dispatch(updateProfilePic(response.data.url));

    } catch(error) {
        throw error;
    }finally{
        dispatch(loaderActions.stopAppLoader());
    }
}


// Funtions to get the profile picture
const fetchProfilePicAPI = () => async dispatch => {
    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_PROFILE_PIC;
    return await dispatch(AwsAmplifyApi.get({apiName, apiPath,excludeClinicHeader:true}));
}

export const fetchProfilePic = () => async dispatch => {

    try {

        const response = await dispatch(fetchProfilePicAPI());
        if(response.error.code) {
            throw response.error
        }
        dispatch(updateProfilePic(response.data.url));

    } catch(_error) {
        dispatch(updateProfilePic(""));
    }
}

const updateProfileAttributesAPI = (given_name, family_name, phone_number, address) => async dispatch => {

    const apiName = APIEndpoints.NHCUSERAPI.name;
    const apiPath = APIPaths.USER_DETAILS;

    const body = {
        first_name: given_name,
        last_name: family_name,
        phone_number,
        address
    }

    return await dispatch(AwsAmplifyApi.put({apiName, apiPath, body}));
}

export const updateProfileAttributes = (given_name, family_name, phone_number, address) => async dispatch => {

    try {

        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PROFILE));

        await dispatch(updateProfileAttributesAPI(given_name, family_name, phone_number, address));
        // if(response.error.code) {
        //     throw response.error
        // }
        dispatch(setUserAttributes({
            firstName:  given_name,
            lastName: family_name,
            phoneNumber: phone_number,
            address,
          }));
        dispatch(loaderActions.stopAppLoader());
    } catch(error) {
        dispatch(loaderActions.stopAppLoader());
        throw error;
    }

}

// ******************************************
// Set allowed user permissions for various clinic
// ******************************************

const fetchUserPermissionsApi = () => async dispatch =>{
    const apiName = APIEndpoints.NHCUSERACCESSAPI.name
    const apiPath = APIPaths.CLINIC_BASED_PERMISSIONS
    return await dispatch(AwsAmplifyApi.get({apiName, apiPath,excludeClinicHeader:true}));

}

export const fetchUserPermissions = () => async dispatch =>{
    try{
        const response = await dispatch(fetchUserPermissionsApi());
        dispatch(updateLoginedUserPermission(response.data));
    }catch(error){
        throw error;
    }
}
