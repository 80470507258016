import React, { Fragment } from "react";
import LayoutWrpper from "./LayoutWrapper";
import Footer from "../Footer";
import LeftMenuAndHeader from '../LeftMenuAndHeader/';
const defaultOpts = {showLeftMenu:true,contentClass:'page-content'};
const WithHeaderAndFooter = (Component,opt=defaultOpts) => {

  return props => (
      <LayoutWrpper>
        <LeftMenuAndHeader {...(opt || defaultOpts)}>
          <Component {...props} />
        </LeftMenuAndHeader>
        {/* <Footer /> */}
      </LayoutWrpper>
  );

};

export default WithHeaderAndFooter;
