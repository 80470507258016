import * as Auth from "aws-amplify/auth";
import { loginActions } from "./";

export const signup = (name, phoneNumber, email, password, captchToken) => async dispatch => {
  //Invite Flow Flag determines whether Registration is needed or not
  const is_first_time_login = "0";
  await Auth.signUp({ 
    username: email, 
    password,
    options: {
      userAttributes: { email, phone_number: phoneNumber, given_name: name, "custom:is_first_time_login": is_first_time_login}
    } 
  });//,captchToken
  return dispatch(loginActions.setUserEmail(email));
};

export const resendOTP = username => async () => {
  return await Auth.resendSignUpCode({username});
};

export const signupConfirm = (username, confirmationCode) => async () => {
  return await Auth.confirmSignUp({username, confirmationCode});
};
