export const WIN_CLICK = 'click';
export const WIN_RESIZE = 'resize';
export const WIN_SCROLL = 'scroll';
export const WIN_TOUCH_MOVE = 'touchmove'
export const SCROLL_TO_TOP = 'scroll:top';

export const SHOW_GLOBAL_ALERT = 'alertg:show';
export const HIDE_GLOBAL_ALERT = 'alertg:hide';
export const SHOW_GLOBAL_TOAST = 'toastG:show';
export const HIDE_GLOBAL_TOAST = 'toastG:hide';
export const SHOW_ALERT = 'alert:show';
export const HIDE_ALERT = 'alert:hide';
export const AFTER_LOAD = 'load:done';
