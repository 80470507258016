

export const useStyles = theme => ({
  registrationHeaderWrapper: {
    padding: '0 125px',
    background: theme.palette.new.background.page,
    '&.no-logo':{
      "& h1": { visibility:'hidden' }
    },
    [theme.breakpoints.down("md")]: { padding: '0 25px' },
  },
  logoContainer: {
    "& h1": {
      margin: 0,
      lineHeight: 0,
     '& a':{ textDecoration: 'none' },
      "& img": { width: 180 },
    },
  },
  contentContainer:{
    minHeight:'calc(100vh - 88px)',
    background: theme.palette.new.background.page
  },
  footerText:{
    padding: '4px 0',
    background: theme.palette.background.primary,
    textAlign: 'center',
    fontWeight:600,
    color:'var(--sm_primary)'
  }
});
  