import { lazy } from "react";
import {SettingsRoutes} from "@settings/Routes";
import {LeadGenerationRoutes} from "@lead-generation/Routes";
import {AIServiceRoutes} from "@ai-services/Routes";
import {GlobalRoutePath} from "./constants"

const Login = /* #__LOADABLE__ */() => import("@components/Authentication/Login");
const Signup = /* #__LOADABLE__ */() => import("@components/Authentication/Signup");
const SignUpConfirm = /* #__LOADABLE__ */() => import("@components/Authentication/SignupConfirm");
const ForgotPassword = /* #__LOADABLE__ */() => import("@components/Authentication/ForgotPassword");
const ForgotPasswordConfirm = /* #__LOADABLE__ */() => import("@components/Authentication/ForgotPasswordConfirm");
const ChangePassword = /* #__LOADABLE__ */() => import("@components/Authentication/ChangePassword");
const TermsAndConditions = /* #__LOADABLE__ */() => import("@components/Authentication/TermsConditions");
const Home = /* #__LOADABLE__ */() => import("@components/Home");

const Dashboard = /* #__LOADABLE__ */() => import("@components/Dashboard");

const LeadGeneration = /* #__LOADABLE__ */() => import("@components/LeadGeneration");
const UserProfile = /* #__LOADABLE__ */() => import("@components/UserProfile");

const ADTAutomation = /* #__LOADABLE__ */() => import("@components/Services/ADTAutomation");
const ADTAutomationHistory = /* #__LOADABLE__ */() => import("@components/Services/ADTAutomation/UploadFileHistory");

const Settings = /* #__LOADABLE__ */() => import("@components/Settings");
const AIService = /* #__LOADABLE__ */() => import("@components/AIServices");

const Patient360 = /* #__LOADABLE__ */() => import("@components/Services/Patient360");

const PatientTrial = /* #__LOADABLE__ */() => import("@components/Services/PatientTrial");
const SmartScheduler = /* #__LOADABLE__ */() => import("@components/Services/SmartScheduler");

const SmartForms = /* #__LOADABLE__ */() => import('@components/SmartForms');
const EFax = /* #__LOADABLE__ */() => import('@components/Services/EFax');

const Routes = {
  LOGIN: {
    name: "login",
    layout:"withRegistrationHeader",
    loadablComp:Login,
    layoutOpt:{headerClass:'no-logo'}
    //componentPath: '@components/Authentication/Login',
  },
  SIGNUP: {
    name: "signup",
    layout:"withRegistrationHeader",
    loadablComp:Signup,
    layoutOpt:{headerClass:'no-logo'}
    //componentPath: '../components/Authentication/Signup',
  },
  SIGNUP_CONFIRM: {
    name: "signup confirm",
    layout:"withRegistrationHeader",
    loadablComp:SignUpConfirm,
    layoutOpt:{headerClass:'no-logo'}
    //componentPath: '@components/Authentication/SignupConfirm',
  },
  FORGOT_PASSWORD: {
    name: "forgot password",
    layout:"withRegistrationHeader",
    loadablComp:ForgotPassword,
    layoutOpt:{headerClass:'no-logo'}
    //componentPath: '@components/Authentication/ForgotPassword',
  },
  FORGOT_PASSWORD_CONFIRM: {
    name: "forgot password confirmation",
    layout:"withRegistrationHeader",
    loadablComp:ForgotPasswordConfirm,
    layoutOpt:{headerClass:'no-logo'}
    //componentPath: '@components/Authentication/ForgotPasswordConfirm',
  },
  CHANGE_PASSWORD: {
    name: "change password",
    layout:"withHeaderAndFooter",
    authRequired:true,
    loadablComp:ChangePassword,
    layoutOpt:{showLeftMenu:false,contentClass:'page-content p-0',containerClass:'no-scroll'},
    //componentPath: '@components/Authentication/ChangePassword',
  },
  TERMS_AND_CONDITIONS: {
    name: "terms and conditions",
    layout:"withRegistrationHeader",
    loadablComp:TermsAndConditions,
    //componentPath: '@components/Authentication/TermsConditions',
  },
  LEAD_GENERATION: {
    name: "lead generation",
    authRequired:true,
    layout:"withWhiteBgLogo",
    nextedRoutes:LeadGenerationRoutes,
    loadablComp:LeadGeneration,
    //componentPath: '@components/Authentication/Login',
  },
  DEFAULT: {
    name: "home",
    exact:true,
    layout:"withPlainHeader",
    loadablComp:Home
  },
  HOME: {
    name: "home",
    layout:"withPlainHeader",
    loadablComp:Home
  },
  LANDING: {
    name: "landing",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp:Dashboard
  },
  DASHBOARD: {
    name: "my dashboard",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp:Dashboard
  },
  USER_PROFILE: {
    name: "user profile",
    authRequired:true,
    layout:"withHeaderAndFooter",
    layoutOpt:{showLeftMenu:false},
    loadablComp: UserProfile,
  },
  ADT_AUTOMATION:{
    name: "adt automation",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: ADTAutomation
  },
  ADT_AUTOMATION_HISTORY:{
    name: "adt automation history",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: ADTAutomationHistory
  },
  SETTINGS: {
    name: "settings",
    basePath:"/settings",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: Settings,
    nextedRoutes:SettingsRoutes
  },
  AI_SERVICES: {
    name: "ai-services",
    authRequired:true,
    layout:"withHeaderAndFooter",
    layoutOpt:{showLeftMenu:false,contentClass:'page-content p-0'},
    nextedRoutes:AIServiceRoutes,
    loadablComp: AIService,
  },
  PATIENT360: {
    name: "patient360",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: Patient360,
  },
  PATIENTTRIAL: {
    name: "patienttrial",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: PatientTrial,
  },
  TRIAL_VIEW: {
    name: "patienttrial view",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: PatientTrial,
  },
  VIEW: {
    name: "patient view",
    authRequired:true,
    layout:"withHeaderAndFooter",
    loadablComp: Patient360,
  },
  SMART_SCHEDULER: {
    name: "smart scheduler",
    authRequired:true,
    layout:"withHeaderAndFooter",
    layoutOpt:{showLeftMenu:false,contentClass:'page-content p-0'},
    loadablComp: SmartScheduler,
  },
  SMART_FORMS: {
    name:  "smart-forms",
    authRequired: true,
    layout:"withHeaderAndFooter",
    layoutOpt:{ showLeftMenu: false, contentClass: 'page-content p-0'},
    loadablComp: SmartForms
  },
  EFAX: {
    name: "eFax",
    authRequired:true,
    layout:"withHeaderAndFooter",
    layoutOpt:{showLeftMenu:false,contentClass:'page-content p-0 efax'},
    loadablComp: EFax,
  }

};
Object.keys(Routes).forEach(rkey=>{
  if(GlobalRoutePath[rkey]){
    Routes[rkey] = {...Routes[rkey],...GlobalRoutePath[rkey]};
  }
  else{
    console.log('GlobalRoute Path info missing for',rkey)
  }
})
export const AuthRequiredPaths = Object.values(Routes).filter(r=>r.authRequired).map(r=>r.nextedRoutes?[r.basePath,...Object.values(r.nextedRoutes).map(nr=>nr.fullPath)]:r.path).flat();
export const GlobalRoutes = Routes


