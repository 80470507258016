Array.prototype.shuffle = function () {
    return this.map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);
};

Array.prototype.remove = function (value) {
    const i = this.indexOf(value);
    i > -1 && this.splice(i,1);
    return this;
};