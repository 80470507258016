import React, { useState,useEffect,useRef } from "react";
import { useDispatch,useSelector,shallowEqual } from 'react-redux';
import { useStyles } from "./styles";
import sessionLogout from '../../assets/images/auto-loggedout.png';
import sessionTimedOut from '../../assets/images/session-timingout.png';
import {Modal, Card, CardHeader, CardContent,Button,Typography} from '@mui/material';

import { loginActions } from "../../Redux/actionCreators/UserActions";
const ONE_SEC = 1000;
const ONE_MIN = 60*ONE_SEC;
const DEFAULT_EXPIRY = 60*ONE_MIN;
const TickTimer = ({startTime=Date.now(),endTime,interval=ONE_SEC,onEnd})=>{
    const [state,setState] = useState({time:(endTime-startTime)/ONE_SEC})
    const timer = useRef();
    const updateTimer = ()=>{
        const rtime = endTime-Date.now();
        if(rtime>0){
            const nt = rtime/ONE_SEC;
            setState({time:state.time == nt?(state.time+1):nt})
        }
        else{
            onEnd(startTime)
            clearTimer();
            setState({time:0});
        }
    }
    const clearTimer = ()=>{
        timer.current && clearTimeout(timer.current);
        timer.current = null;
    }
    useEffect(()=>{
        clearTimer();
        timer.current = setInterval(updateTimer, interval)
        return ()=> clearTimer();
    },[endTime])
    useEffect(()=>{
        return()=>clearTimer()
    },[])
    
    return parseInt(state.time)
}

const SessionHandler = ()=>{
    const dispatch = useDispatch(); 
    const classes = useStyles();
    const { isLoggedIn,sessionData } = useSelector(state => state.userReducer,shallowEqual);
    const [show,setShow] = useState(false);
    const timer = useRef();
    const stateInfo = useRef({});
    const logout = async () => {
        console.log('logout',sessionData);
        clearSessionTimer();
        await dispatch(loginActions.logout({email:sessionData.email,manual:!!sessionData.inForce,logout:true}));
    }
    const renewSession = async () => {
        //console.log('renewSession',sessionData);
        clearSessionTimer();
        setShow(false);
        await dispatch(loginActions.renewToken);
    }
    const aboutToExpireTime = 60*ONE_SEC;
    const onTimerEnd = (startTime)=>{
        console.log('autologout ',startTime,show);
        if(startTime == stateInfo.current.st){
            clearSessionTimer();
            setShow(false);
            logout();
        }
    }
    const setSessionTimer = (time)=>{
        //console.log('setSessionTimer',(time/ONE_SEC),show,new Date(),sessionData.expiry);
        clearSessionTimer();
        show && setShow(false);
        timer.current = setTimeout(()=>{
            setShow({aboutToExpire:true,rt:aboutToExpireTime,st:Date.now(),et:Date.now()+aboutToExpireTime});
        },time)
    }
    const clearSessionTimer = ()=>{
        timer.current && clearTimeout(timer.current);
        timer.current = null;
    }
    const okHandler = async ()=>{
        !show.expired && await logout();
        show.expired && setShow(false);
    }
    useEffect(()=>{
        stateInfo.current = show?{...show}:{};
    },[show])
    useEffect(()=>{
        //console.log('session-info',{isLoggedIn,},sessionData);
        if(!isLoggedIn && sessionData.manual){//manual logout
            clearSessionTimer();
            setShow(false);
            //console.log('manual logout',new Date());
        }
        else if(!isLoggedIn && sessionData.logout){//for after session out popup
            setShow({expired:true})
        }
        else if(isLoggedIn && sessionData.expired){//manual expiry due to token expiry
            logout();
        }
        else if(isLoggedIn && sessionData.lat){//init/refresh session timer
            //console.log('session-reset',sessionData);
            //setSessionTimer(aboutToExpireTime*2);
            setSessionTimer((sessionData.expiry||DEFAULT_EXPIRY)-aboutToExpireTime);
        }
    },[isLoggedIn,sessionData.lat,sessionData.expired,sessionData.logout,sessionData.manual])
    //console.log('session timer',timer.current);
    return(show && <Modal open className={classes.sessionExpModal}>
        <Card className={classes.sessionExpCard}>
            <CardHeader title={<>
                <img src={show.expired?sessionLogout:sessionTimedOut} />
                <h2>{show.expired?"You're logged out":'Session about to timeout'}</h2>
            </>} />
            <CardContent>
                <Typography variant="body2" component="p">
                  {show.expired?<>We're sorry. You're automatically logged out due to inactivity. Please re-login to continue.</>:<>Your session is about to expire. Logging out automatically in <TickTimer key={show.st} startTime={show.st} endTime={show.et} onEnd={onTimerEnd}/> seconds</>}
                </Typography>
            </CardContent>
            <div className="modalActions">
                {show.expired && <Button className="button primary" color="primary"  onClick={okHandler}>Proceed to login</Button>}
                {!show.expired && <>
                    <Button className="button" color="primary"  onClick={okHandler}>Logout</Button>
                    <Button className="button primary" color="primary"  onClick={renewSession}>Continue Session</Button>
                </>}
            </div>
        </Card>
    </Modal>)
}

export default SessionHandler